import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FiltersGroup from '../../../components/organisms/filtersGroup/FiltersGroup';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AccountEstimations from './tabs/accountEstimations/AccountEstimations';
import AccountProfile from './tabs/accountProfile/AccountProfile';
import AppContext from '../../../context/AppContext';
import { useNavigate, useParams } from 'react-router-dom';

function Account() {
  const [currentTab, setCurrentTab] = useState(0);
  const { getUserId } = useContext(AppContext);
  const [activeTabIndexChanger, setActiveTabIndexChanger] = useState(undefined);
  const [pageTitle, setPageTitle] = useState('');
  const navbarRef = useRef();
  const navigate = useNavigate();
  const { tabParam } = useParams();

  useEffect(() => {
    if (!getUserId()) navigate('/connexion');
  }, []);

  useEffect(() => {
    let tab = 0;
    switch (tabParam) {
      case 'mon-profil':
        tab = 1;
        break;
    }

    setCurrentTab(tab);
    setActiveTabIndexChanger(tab);
  }, [tabParam]);

  useEffect(() => {
    switch (currentTab) {
      case 0:
        setPageTitle('Mes estimations');
        break;
      case 1:
        setPageTitle('Mon compte');
    }
  });

  function changeTab(tab) {
    setCurrentTab(tab);
    navigate(`/mon-compte/${tab === 0 ? 'mes-estimations' : 'mon-profil'}`);
  }

  return (
    <div className='page-container'>
      <section className='container'>
        <Breadcrumbs
          routes={[
            { name: 'Accueil', to: '/' },
            { name: pageTitle, to: '/mon-compte/' + tabParam },
          ]}
        />
        <Banner title={pageTitle} />
      </section>
      <div className='sticky-container' ref={navbarRef}>
        <FiltersGroup
          changeActiveTabIndex={activeTabIndexChanger}
          data={[
            {
              name: 'Mes estimations',
              onClick: changeTab,
              params: [0],
            },
            {
              name: 'Mon compte',
              onClick: changeTab,
              params: [1],
            },
          ]}
        />
      </div>
      {currentTab === 0 && <AccountEstimations />}
      {currentTab === 1 && <AccountProfile navbarRef={navbarRef} />}
    </div>
  );
}

export default Account;
