import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Radio from '../../../../molecules/formComponents/radio/Radio';
import Checkbox from '../../../../molecules/formComponents/checkbox/Checkbox';
import Select from '../../../../molecules/formComponents/select/Select';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import { fetchDropdownValuesApi } from '../../../../../api/ParcoursApi';
import { getSelectValue } from '../../../../../utils/Utils';
import ZoneInfluence from '../../../../atoms/icons/parcours/zoneInfluence/ZoneInfluence';
import { useWindowSize } from '@uidotdev/usehooks';
import Tuto from '../../../../atoms/icons/general/tuto/Tuto';

function RayonnementImplantation({ id, index }) {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    showTooltip,
    dependanceLocalCommercial,
    setDependanceLocalCommercial,
    moveToNextStep,
    step,
    getStepToGo,
    setGoToStep,
    setStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillSelectsFromEstimationValues,
  } = useContext(ParcoursContext);
  const [dropdownsValues, setDropdownsValues] = useState({});
  const { width } = useWindowSize();

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    if (
      estimationValues?.step >= 8 &&
      !estimationValues?.emplacement?.qualite_secteur?.id &&
      !estimationValues?.emplacement?.qualite_implantation?.id
    ) {
      setValue('dependance_local_commercial', true);
      setDependanceLocalCommercial(true);
    } else {
      fillSelectsFromEstimationValues(
        'emplacement',
        ['qualite_secteur', 'qualite_implantation_zone'],
        setValue,
        estimationValues,
      );
    }
    setValue('type_ville', estimationValues?.emplacement?.type_ville?.id + '');
    setValue('note_emplacement', estimationValues?.emplacement?.note_emplacement);
  }, [estimationValues, dropdownsValues]);

  async function formSubmit(values) {
    postValues({
      emplacement: {
        type_ville: {
          id: values.type_ville,
        },
        qualite_secteur: { id: values.dependance_local_commercial ? null : getSelectValue(values.qualite_secteur) },
        qualite_implantation_zone: {
          id: values.dependance_local_commercial ? null : getSelectValue(values.qualite_implantation_zone),
        },
        note_emplacement: values.note_emplacement,
      },
      notes: {
        note_emplacement: values.note_emplacement,
      },
    });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    const values = {};
    try {
      values.qualiteSecteur = (await fetchDropdownValuesApi('qualite_secteur')).data;
    } catch (error) {
      console.error(error);
    }
    try {
      values.qualiteImplantationRue = (await fetchDropdownValuesApi('qualite_implantation_zone')).data;
    } catch (error) {
      console.error(error);
    }

    setDropdownsValues(values);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>Rayonnement & implantation</h2>

        <Radio
          padding='2rem'
          label={
            <>
              zone d’influence de l’affaire
              <Tuto onClick={() => showTooltip('step_8_tuto_1.webp')} />
            </>
          }
          register={register}
          name='type_ville'
          className='zone-influence-radio'
          error={errors?.type_ville && errors.type_ville.message}
          content={[
            {
              label: (
                <>
                  {width > 1200 && <br />}
                  Rayonnement local
                  {width > 1200 && <br />}
                  <ZoneInfluence size='xs' className='parcours-zone-influence' />
                </>
              ),
              value: 1,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />}
                  départemental
                  {width > 1200 && <br />}
                  <ZoneInfluence size='sm' className='parcours-zone-influence' />
                </>
              ),
              value: 2,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />}
                  régional
                  {width > 1200 && <br />}
                  <ZoneInfluence size='lg' className='parcours-zone-influence' />
                </>
              ),
              value: 3,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />} national&nbsp;ou&nbsp;international
                  {width > 1200 && <br />}
                  <ZoneInfluence size='xl' className='parcours-zone-influence' />
                </>
              ),
              value: 4,
            },
          ]}
        />
        <Checkbox
          label={
            <>
              l'activité ne dépend pas d’un local commercial
              <Tuto onClick={() => showTooltip('step_8_tuto_2.webp')} />
            </>
          }
          name='dependance_local_commercial'
          register={register}
          onChange={e => setDependanceLocalCommercial(e.target.checked)}
        />
        <Select
          visible={!dependanceLocalCommercial}
          label={
            <>
              qualité de secteur et de clientèle
              <Tuto onClick={() => showTooltip('step_8_tuto_3.webp')} />
            </>
          }
          name='qualite_secteur'
          register={register}
          error={errors?.qualite_secteur && errors.qualite_secteur.message}>
          {dropdownsValues?.qualiteSecteur?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <Select
          visible={!dependanceLocalCommercial}
          label={
            <>
              qualité d’implantation dans la zone
              <Tuto onClick={() => showTooltip('step_8_tuto_4.webp')} />
            </>
          }
          name='qualite_implantation_zone'
          register={register}
          error={errors?.qualite_implantation_zone && errors.qualite_implantation_zone.message}>
          {dropdownsValues?.qualiteImplantationRue?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <Textarea
          register={register}
          name='note_emplacement'
          label='notes et remarques'
          className='mt-md'
          error={errors?.note_emplacement && errors.note_emplacement.message}
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default RayonnementImplantation;
