import './Textarea.css';
import React, { useEffect, useRef } from 'react';
import { max2250Validation } from '../../../../utils/formValidation/FormValidation.js';
import Warning from '../../../atoms/icons/general/warning/Warning';
import { toggleCollapseElement } from '../../../../utils/Utils';

function Textarea({
  className = '',
  label,
  register,
  visible,
  min,
  max,
  error,
  name,
  defaultValue,
  validation = { max2250Validation },
  onChange,
  onKeyDown,
  onBlur,
  onFocus,
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseElement(ref.current, visible);
  }, [visible]);

  const inputRegister = register && (visible || visible === undefined) ? register(name, validation) : null;

  return (
    <div className={`w-100  ${className}`} ref={ref}>
      <div className='field-container textarea-container'>
        <label className='form-label' htmlFor={name}>
          {label}
        </label>
        <textarea
          onKeyDown={onKeyDown}
          disabled={visible !== undefined && !visible}
          name={name}
          id={name}
          minLength={min}
          maxLength={max}
          className={`form-textarea ${error ? 'with-error' : ''}`}
          {...inputRegister}
          onChange={e => {
            register && inputRegister.onChange(e);
            onChange && onChange(e);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          defaultValue={defaultValue}
        />
        {error && (
          <div className='form-error'>
            <Warning /> <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Textarea;
