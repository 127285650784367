import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../../context/AppContext';
import UnfilledButton from '../../../molecules/buttons/unfilledButton/UnfilledButton';
import FilledButton from '../../../molecules/buttons/filledButton/FilledButton';
import Select from '../../../molecules/formComponents/select/Select';

function AddBilanYearModal({ bilanYear, preSelectedYear, selectedYears, onSubmit }) {
  const [value, setValue] = useState(preSelectedYear || '');
  const { setModalVisible } = useContext(AppContext);

  useEffect(() => {
    setValue(preSelectedYear || '');
  }, [bilanYear, preSelectedYear]);

  const generateYears = (bilanYear, range) => {
    const year = parseInt(bilanYear, 10);
    const years = [];
    for (let i = year - range; i <= year + range; i++) {
      if (i !== year && !selectedYears.includes(i)) {
        years.push(i);
      }
    }
    return years;
  };

  const years = generateYears(bilanYear, 15);

  return (
    <form>
      <Select value={value} onChange={e => setValue(e.target.value)} label='Année du bilan'>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' onClick={() => onSubmit(value)}>
          Ajouter le bilan
        </FilledButton>
      </div>
    </form>
  );
}

export default AddBilanYearModal;
