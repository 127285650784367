import React from 'react';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../components/molecules/banner/Banner';
import './ParametrageCookies.css';
import ParametrageCookiesForm from '../../../components/forms/cookies/parametrageCookiesForm/ParametrageCookiesForm';

function ParametrageCookies() {
  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            {
              name: 'Accueil',
              to: '/',
            },
            {
              name: 'Cookies: Gérer les cookies',
              to: '/parametrage-cookies',
            },
          ]}
        />
        <Banner
          title='Gestion de vos préférences sur les cookies 🍪'
          subtitle={"Les cookies permettent d'améliorer votre expérience de navigation"}
        />
        <ParametrageCookiesForm />
      </section>
    </>
  );
}

export default ParametrageCookies;
