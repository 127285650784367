import TwistedArrow from '../../atoms/icons/pdf/twistedArrow/TwistedArrow';
import './EstimationsRangePdf.css';
import React from 'react';

// data = {
//   hasTopArrows: false,
//   values: [
//     {
//       estimation: '',
//       description: <></>,
//     },
//     {
//       estimation: '',
//       description: <></>,
//     },
//     {
//       estimation: '',
//       description: <></>,
//     },
//   ],
// };

function getEmbededValue(value) {
  return value ? (value + '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €' : '';
}

function EstimationsRangePdf({ data, type, className = '' }) {
  const renderSideEstimation = (index, arrowRotation = '0deg', arrowColor = 'var(--red)') => (
    <div className='side-estimation-range-container'>
      <div className='estimation-range-subtext'>
        {data?.hasTopArrows && <TwistedArrow width='30px' color={arrowColor} rotation={arrowRotation} />}
      </div>
      <div className='estimation-range-value bold'>{getEmbededValue(data?.values[index]?.estimation)}</div>
      <div className='estimation-range-subtext'>{data?.values[index].description}</div>
    </div>
  );

  const renderCenterEstimation = () => (
    <div className='center-estimation-range-container'>
      <div className='estimation-range-subtext'></div>
      <div className='estimation-range-value bold'>{getEmbededValue(data?.values[1]?.estimation)}</div>
      <div className='estimation-range-subtext'>{data?.values[1]?.description}</div>
    </div>
  );

  return (
    <div className={`estimations-range-container-pdf ${className}`}>
      {type !== 'ebe_only' && renderSideEstimation(0)}
      {renderCenterEstimation()}
      {type !== 'ebe_only' && renderSideEstimation(2, '180deg', 'var(--green)')}
    </div>
  );
}

export default EstimationsRangePdf;
