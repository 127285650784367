import React from 'react';
import './PagePdf.css';
import PaginationPdf from '../paginationPdf/PaginationPdf';

function PagePdf({ children, pageActuelle, totalPages, innerHTML }) {
  return innerHTML ? (
    <div className='page-pdf' custom-page-number={pageActuelle}>
      <div className='page-pdf-content' dangerouslySetInnerHTML={{ __html: innerHTML }} />
      <div className='page-number'>
        <PaginationPdf currentPage={pageActuelle} totalPages={totalPages} />
      </div>
    </div>
  ) : (
    <div className='page-pdf' custom-page-number={pageActuelle}>
      <div className='page-pdf-content'>{children}</div>
      <div className='page-number'>
        <PaginationPdf currentPage={pageActuelle} totalPages={totalPages} />
      </div>
    </div>
  );
}

export default PagePdf;
