import { checkImage, getCompressedImage, toggleCollapseInput } from '../../../../utils/Utils';
import Warning from '../../../atoms/icons/general/warning/Warning';
import './FileInput.css';
import React, { useEffect, useRef, useState } from 'react';

function FileInput({
  label,
  name,
  register, // don't set if you don't need useForm
  setValue, // useForm setValue function
  bgColor = 'var(--main-color)',
  color = 'var(--white)',
  hoverColor = 'var(--main-color)',
  borderRadius = '10px',
  padding = '5px 20px',
  fileMaxSize = 10,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onClear = () => {},
  value,
  disabled,
  icon,
  visible,
  validation,
  accept = '.png, .jpg, .jpeg, .svg',
  buttonText = 'Importer une image',
  removeButton = true,
  removeButtonText = "Retirer l'image",
  className = '',
}) {
  // TODO rajouter barre chargement
  const [isLoading, setIsLoading] = useState(false);
  const [imgErrorMessage, setImgErrorMessage] = useState('');
  const inputRegister = register && (visible || visible === undefined) ? register(name, validation) : null;

  const ref = useRef(null);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseInput(ref.current, visible);
  }, [visible]);

  function clearInput() {
    register && setValue();
    onClear();
  }

  async function handleChange(e) {
    setIsLoading(true);
    const file = e.target.files[0];
    const errorMsg = checkImage(file, fileMaxSize);

    if (errorMsg) return setImgErrorMessage(errorMsg);

    try {
      const compressedFile = await getCompressedImage(file);

      register && setValue(name, JSON.stringify(compressedFile));
      setImgErrorMessage('');

      if (onChange) onChange(compressedFile);
    } catch (error) {
      setImgErrorMessage("Erreur lors de la lecture de l'image");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className='w-100 d-flex' ref={ref}>
        <div
          className={`file-input-container ${className} ${removeButton ? 'with-remove-button' : ''}`}
          style={{
            '--padding': padding,
            '--background-color': bgColor,
            '--color': color,
            '--hover-color': hoverColor,
            '--border-radius': borderRadius,
          }}>
          {label && (
            <label htmlFor='name' className='form-label'>
              {label}
            </label>
          )}
          <label className='file-input-label filled-button' htmlFor={name}>
            <div className={`button-loader-container ${isLoading ? '' : 'hidden'}`}>
              <div className='button-loader' />
            </div>
            {icon}
            {buttonText}
          </label>
          <input
            id={name}
            value={value !== undefined ? value : undefined}
            {...inputRegister}
            type='file'
            className='file-input'
            onChange={e => {
              inputRegister && inputRegister.onChange(e);
              handleChange(e);
            }}
            disabled={(visible !== undefined && !visible) || disabled}
            onFocus={e => onFocus && onFocus(e)}
            onBlur={e => onBlur && onBlur(e)}
            accept={accept}
          />
          {removeButton && (
            <div className='file-input-remove-button' onClick={clearInput}>
              {removeButtonText}
            </div>
          )}
          {imgErrorMessage && (
            <div className='form-error'>
              <Warning /> <p>{imgErrorMessage}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FileInput;
