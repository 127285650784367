import './LogoEntreprisePdf.css';
import React from 'react';

function LogoEntreprisePdf({ width = '100px', height = '100px', src, alt = 'Logo entreprise', className = '' }) {
  return (
    <div className={`logo-entreprise-container ${className}`}>
      <img width={width} height={height} src={src} alt={alt} />
    </div>
  );
}

export default LogoEntreprisePdf;
