import { useNavigate, useSearchParams } from 'react-router-dom';
import ConnexionForm from '../../../components/forms/compte/connexionForm/ConnexionForm';
import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import AppContext from '../../../context/AppContext';
import './Login.css';
import React, { useContext, useEffect } from 'react';

function Login() {
  const { getUserId, createNotification } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Display notification if user is redirected by axios config
  useEffect(() => {
    switch (searchParams.get('state')) {
      case 'bad_credentials':
        createNotification(<>Votre session a expiré. Veuillez vous reconnecter.</>, 'var(--red)', 'var(--grey)');
        break;
      case 'account_reactivation':
        createNotification(<>Votre compte a été réactivé avec succès, vous pouvez maintenant vous reconnecter.</>);
        break;
    }
    searchParams.delete('state');
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    if (getUserId() && !localStorage.getItem('account_not_activated')) navigate('/mon-compte/mon-profil');
  }, [getUserId()]);

  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            { name: 'Accueil', to: '/' },
            { name: 'Connexion', to: '/connexion' },
          ]}
        />
        <Banner title='Connexion' subtitle='Bienvenue, connectez-vous afin de profiter de nos services' />
        <div className='connexion-page-container'>
          <ConnexionForm />
        </div>
      </section>
    </>
  );
}

export default Login;
