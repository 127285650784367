import { updateVisitingCardApi } from '../../../api/VisitingCardApi';
import { toggleCollapseElement } from '../../../utils/Utils';
import Bracket from '../../atoms/icons/general/bracket/Bracket';
import './ExpendableGroup.css';
import React, { useEffect, useRef, useState, useContext } from 'react';
import AppContext from '../../../context/AppContext';

// data =  [
//     {
//         title: "",
//         content: ""
//     },
// ]

function ExpendableGroup({
  data,
  className = '',
  id = 'expendable-group',
  refreshData, // Only for visiting cards
}) {
  const [openedElement, setOpenedElement] = useState(-1);
  const parentRef = useRef(null);

  const { createNotification } = useContext(AppContext);

  useEffect(() => {
    for (let i = 0; i < parentRef.current.children.length; i++) {
      const child = parentRef.current.children[i];
      if (child.classList.contains('active')) {
        toggleCollapseElement(child.children[1], true, 60);
      } else {
        toggleCollapseElement(child.children[1], false, 60);
      }
    }
  }, [openedElement]);

  function changeOpenedElement(index) {
    setOpenedElement(value => (value === index ? -1 : index));
  }

  //Only for visiting cards
  async function handleDefaultVisitingCard(e) {
    e.preventDefault();
    e.stopPropagation();

    try {
      await updateVisitingCardApi(e.target.getAttribute('card-id'), { is_default: e.target.checked ? 1 : 0 });

      const checkboxes = document.getElementsByClassName('expendable-hidden-checkbox');
      for (const checkbox of checkboxes) {
        if (!checkbox.isSameNode(e.target) && checkbox.checked)
          await updateVisitingCardApi(checkbox.getAttribute('card-id'), { is_default: 0 });
      }

      refreshData();
      createNotification(<>La carte de visite par défaut a été modifiée avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la modification de la carte de visite par défaut. Veuillez réessayer.</>,
      );
    }
  }

  return (
    <>
      <input type='hidden' id={id} value={openedElement} />
      <section className={'expendable-group-container ' + className} ref={parentRef}>
        {data.map((element, key) => {
          return (
            <div className={'expendable-container ' + (openedElement === key ? 'active' : '')} key={key}>
              <div className='expendable-top' onClick={() => changeOpenedElement(key)}>
                <div className='expendable-title'>{element.title}</div>
                {refreshData && (
                  <div className='expendable-checkbox-container'>
                    <label htmlFor={`default_checkbox_${element.id}`}>Par défaut</label>
                    <input
                      type='checkbox'
                      className='expendable-hidden-checkbox'
                      onChange={handleDefaultVisitingCard}
                      card-id={element.id}
                      checked={element.is_default}
                      id={`default_checkbox_${element.id}`}
                    />
                    <label
                      htmlFor={`default_checkbox_${element.id}`}
                      className={'expendable-checkbox ' + (element.is_default ? 'expendable-checked' : '')}></label>
                  </div>
                )}
                <div className='expendable-arrow'>
                  <Bracket width='20px' rotation={openedElement === key ? '0' : '180deg'} />
                </div>
              </div>
              <div className='expendable-content'>{element.content}</div>
            </div>
          );
        })}
      </section>
    </>
  );
}

export default ExpendableGroup;
