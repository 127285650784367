import React, { Fragment } from 'react';
import './DescriptiveText.css';
import H3Pdf from '../h3Pdf/H3Pdf';

// data = {
//   title: '',
//   content: [
//     {
//       normalText: '',
//       boldText: '',
//     },
//   ],
// };

function DescriptiveText({ data }) {
  return (
    <>
      <div possible-page-break='true' />
      <div className='default-margin-placeholder' />
      {data?.title && <H3Pdf title={data.title} />}
      {data?.content.map((textGroup, index) => {
        return (
          <Fragment key={index}>
            <p key={index} className='normal-bold-text-pdf'>
              {textGroup.normalText}
              <span>
                {textGroup.normalText === 'Descriptif des locaux : ' && <br />}
                {textGroup.boldText
                  .split('\n')
                  .filter(section => section.length)
                  .map((section, index) => (
                    <Fragment key={index}>
                      {index ? (
                        <>
                          <br />
                          <span possible-page-break='true' />
                        </>
                      ) : null}
                      {section}
                    </Fragment>
                  ))}
              </span>
            </p>
            <div possible-page-break='true' />
          </Fragment>
        );
      })}
    </>
  );
}

export default DescriptiveText;
