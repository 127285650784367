import axiosInstance from './AxiosConfig';

//GET dropdown values
export const fetchDropdownValuesApi = async dropdownName =>
  axiosInstance.get(`/parcours/dropdown?field=${dropdownName}`);

//GET types commerce
export const fetchTypesCommerceApi = async idCommerce => axiosInstance.get(`/parcours/affaires/${idCommerce}`);

//GET all activites
export const fetchAllActivitesByIdApi = async () => axiosInstance.get('/liste-activite?ids=true');

// GET special inputs
export const fetchCompteResultatSpecialInputs = async estimationId =>
  axiosInstance.get(`/parcours/ca-inputs/${estimationId}`);
