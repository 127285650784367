import Input from '../../../../molecules/formComponents/input/Input';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ParcoursContext } from '../../../../../context/ParcoursContext';

function Clientele({ id, index }) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    dependanceLocalCommercial,
    setDependanceLocalCommercial,
    moveToNextStep,
    step,
    getStepToGo,
    setGoToStep,
    setStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'clientele',
      ['type_clientele', 'delais_reglements', 'proportions_impayes', 'fidelite_client'],
      setValue,
      estimationValues,
    );
  }, [estimationValues]);

  async function formSubmit(values) {
    postValues({ clientele: values });

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>Clientèle</h2>
        <div className='row-1000'>
          <Input
            register={register}
            name='type_clientele'
            label='type de clientèle'
            error={errors?.type_clientele && errors.type_clientele.message}
          />
          <Input
            register={register}
            name='delais_reglements'
            label='Délais de règlement'
            error={errors?.delais_reglements && errors.delais_reglements.message}
          />
        </div>
        <div className='row-1000'>
          <Input
            register={register}
            name='proportions_impayes'
            label="Proportion d'impayés sur clientèle"
            error={errors?.proportions_impayes && errors.proportions_impayes.message}
          />
          <Input
            register={register}
            name='fidelite_client'
            label='Ancienneté / fidélité des clients'
            error={errors?.fidelite_client && errors.fidelite_client.message}
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        </div>
      </fieldset>
    </form>
  );
}

export default Clientele;
