import './H3Pdf.css';
import React from 'react';

function H3Pdf({ title, className = '', notOnSummary }) {
  // Ensure title is a string
  const normalizedTitle = title && typeof title === 'string' ? title.normalize() : '';

  return (
    <div
      h3-title={normalizedTitle}
      not-on-summary={notOnSummary ? 'true' : null}
      className={`text-hr-container ${className}`}>
      <h3>{normalizedTitle}</h3>
      <div className='text-hr-side' />
    </div>
  );
}

export default H3Pdf;
