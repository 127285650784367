import React, { Fragment } from 'react';
import './StrongWeakPointsGroupPdf.css';
import Arrow from '../../atoms/icons/pdf/arrow/Arrow';
import H3Pdf from '../h3Pdf/H3Pdf';

// data = {
//   title: '',
//   content: [
//     {
//       title: '',
//       points: [
//         {
//           isStrongPoint: true,
//           content: '',
//         },
//       ],
//     },
//   ],
// };

function StrongWeakPointsGroupPdf({ data, className = '' }) {
  if (!data || !data.content) {
    return null;
  }

  return (
    <>
      <H3Pdf title={data?.title} className='mt-md' />
      {data?.content.map((pointsGroup, index) => (
        <Fragment key={index}>
          {/* <div className='default-margin-placeholder' /> */}
          <h4 className='strong-weak-points-h4 mt-md mb-sm'>{pointsGroup.title}</h4>
          {pointsGroup?.points.map((point, pointIndex) => {
            const isPreviousPointStrong = pointIndex > 0 && pointsGroup.points[pointIndex - 1].isStrongPoint;
            return (
              <Fragment key={pointIndex}>
                <div
                  className={`strong-weak-point-container-pdf ${!point.isStrongPoint && isPreviousPointStrong ? 'mt-md' : ''}`}>
                  {point.isStrongPoint ? (
                    <div className='strong-weak-point-title-pdf'>
                      <Arrow color='var(--green)' />
                      Force :
                    </div>
                  ) : (
                    <div className='strong-weak-point-title-pdf'>
                      <Arrow color='var(--red)' className='arrow-weak-rotate' />
                      Faiblesse :
                    </div>
                  )}
                  <p className='text-justify'>{point.content}</p>
                </div>
                <div possible-page-break='true' />
              </Fragment>
            );
          })}
        </Fragment>
      ))}
    </>
  );
}

export default StrongWeakPointsGroupPdf;

export function divideStrongWeakPointsIntoPages(pointsData, maxStrongWeakPointsLinesPerPage) {
  const pages = [];
  let currentPage = { title: pointsData.title, content: [] };
  let currentLineCount = 0;

  pointsData.content.forEach(pointsGroup => {
    const groupLines = pointsGroup.points.length + 1;

    if (currentLineCount + groupLines > maxStrongWeakPointsLinesPerPage) {
      pages.push(currentPage);
      currentPage = { title: pointsData.title, content: [] };
      currentLineCount = 0;
    }

    currentPage.content.push(pointsGroup);
    currentLineCount += groupLines;
  });

  if (currentPage.content.length > 0) {
    pages.push(currentPage);
  }

  return pages;
}
