import './SummaryPdf.css';
import React, { Fragment } from 'react';

function SummaryPdf({ summaryChapters, startIndex = 0 }) {
  function generateDots(title) {
    const maxLength = 180;
    const numDots = maxLength - title.length;
    return '.'.repeat(Math.max(0, numDots));
  }

  return (
    <>
      {summaryChapters.map((summaryChapter, index) => (
        <Fragment key={index}>
          <div possible-page-break='true' />
          <div key={index} className='summary-chapter-container'>
            <a href={'#' + generateHrefPdf(summaryChapter.title)} className='chapter-title-container'>
              <span className='chapter-title'>
                {startIndex + index + 1}. {summaryChapter.title}
              </span>
              <span className='summary-dots-line'>
                {generateDots(`${startIndex + index + 1}.${summaryChapter.title}`)}
              </span>
              <span>{summaryChapter.page}</span>
            </a>
            {summaryChapter.subItems &&
              summaryChapter.subItems.map((item, subIndex) => (
                <a href={'#' + generateHrefPdf(item.subtitle)} key={subIndex} className='chapter-subtitle-container'>
                  <span className='subtitle-bullet-point'>•</span>
                  <span>{item.subtitle}</span>
                  <span className='summary-dots-line'>{generateDots(item.subtitle)}</span>
                  <span>{item.page}</span>
                </a>
              ))}
          </div>
        </Fragment>
      ))}
    </>
  );
}

export default SummaryPdf;

export function generateHrefPdf(title) {
  if (!title) return '';
  return title
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replaceAll(/[^a-zA-Z0-9 ]/g, '')
    .replaceAll(' ', '-')
    .toLowerCase();
}

export function generateSummaryChapters(
  pdfContainerRef,
  actualPage,
  summaryChapters,
  depthLevel,
  exceptions = ["Rapport d'estimation", 'Sommaire'],
) {
  const node = pdfContainerRef.current ? pdfContainerRef.current : pdfContainerRef;

  if (node.getAttribute('custom-page-number')) actualPage = parseInt(node.getAttribute('custom-page-number'));

  if (node.id === 'cover-page') return summaryChapters;

  if (node.localName === 'h2' && node.getAttribute('custom-visibility') !== '0') {
    if (!exceptions.includes(node.innerText.trim())) {
      summaryChapters.push({ title: node.innerText, page: actualPage });
    }
  }

  if (node.localName === 'h3' && node.getAttribute('custom-visibility') !== '0') {
    const lastChapter = summaryChapters[summaryChapters.length - 1];
    if (lastChapter) {
      if (!lastChapter.subItems) lastChapter.subItems = [];
      lastChapter.subItems.push({ subtitle: node.innerText, page: actualPage });
    }
  }

  depthLevel++;
  if (depthLevel > 5) return summaryChapters;

  if (node?.children) {
    for (const child of node?.children) {
      summaryChapters = generateSummaryChapters(child, actualPage, summaryChapters, depthLevel, exceptions);
    }
  }

  return summaryChapters;
}
