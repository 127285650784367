import React from 'react';
import './BannerPdf.css';

function BannerPdf({ title, className = '', notOnSummary }) {
  return (
    <div h2-title={title} not-on-summary={notOnSummary ? 'true' : null} className={`banner-pdf-container ${className}`}>
      <h2>{title}</h2>
    </div>
  );
}

export default BannerPdf;
