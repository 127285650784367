import React from 'react';

function InfosBlock({ infos }) {
  return (
    <div className='mt-2xxl'>
      <p style={{ fontWeight: 'bold', fontSize: '25px' }}>{infos.name}</p>
      <p style={{ fontWeight: 'bold', fontSize: '20px' }}>{infos.type}</p>
      <p className='mt-sm text-lg'>{infos.rue}</p>
      <p className='text-lg'>
        {infos.codePostal} {infos.ville}
      </p>
      <p className='mt-lg text-lg'>Siret : {infos.siret}</p>
      {/* <p className='text-lg'>Code APE : infos.apeCode</p> */}
      {/* <p className='text-lg'>Représentant légal : infos.legalRepresentative</p> */}
      <p className='text-lg'>Date de création : {infos.creationDate}</p>
      <p className='text-lg'>Capital social : {infos.capital}</p>
    </div>
  );
}

export default InfosBlock;
