import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './Header.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import EmeLogo from '../../atoms/logos/emeLogo/EmeLogo';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import UnfilledButton from '../../molecules/buttons/unfilledButton/UnfilledButton';
import ArrowSm from '../../atoms/icons/general/arrowSm/ArrowSm';
import { toggleCollapseElement } from '../../../utils/Utils';
import Bracket from '../../atoms/icons/general/bracket/Bracket';
import { useWindowSize } from '@uidotdev/usehooks';
import RoundedDiv from '../../atoms/icons/general/roundedDiv/RoundedDiv';
import User from '../../atoms/icons/general/user/User';
import { logoutUserApi } from '../../../api/CompteApi';
import AppContext from '../../../context/AppContext';

function Header({ className = '' }) {
  const [openedIndexes, _setOpenedIndexes] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });
  const [openedAccountNav, setOpenedAccountNav] = useState(false);
  const [alreadyOpenedIndexes, setAlreadyOpenedIndexes] = useState(openedIndexes);
  const [headerOpened, setHeaderOpened] = useState(false);
  const [mobileOpenedIndex, setMobileOpenedIndex] = useState(-1);
  const [userInitials, setUserInitials] = useState('');
  const navLinksRef = useRef(null);
  const accountNavRef = useRef(null);
  const accountNavContainerRef = useRef(null);

  const { createNotification, setUserId, setModalVisible, setModalContent, getUserData, getUserId } =
    useContext(AppContext);
  const { width } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (width > 1200 && navLinksRef.current) {
      for (const key of Object.keys(openedIndexes)) {
        if (openedIndexes[key] !== alreadyOpenedIndexes[key]) {
          toggleCollapseElement(navLinksRef.current.children[key].children[1], openedIndexes[key]);
          navLinksRef.current.children[key].children[1].classList.toggle('opened-header-section', openedIndexes[key]);
        }
      }
      setAlreadyOpenedIndexes(openedIndexes);
    }
  }, [openedIndexes]);

  useEffect(() => {
    toggleCollapseElement(accountNavRef.current, openedAccountNav);
    accountNavRef.current.classList.toggle('opened-header-section', openedAccountNav);
    toggleMobileMenu(mobileOpenedIndex);
  }, [openedAccountNav]);

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = headerOpened ? 'hidden' : 'auto';
  }, [headerOpened]);

  useEffect(() => {
    function closeHeaderDropdown(event) {
      if (accountNavContainerRef.current && !accountNavContainerRef.current.contains(event.target)) {
        setOpenedAccountNav(false);
      }
    }

    document.addEventListener('mousedown', closeHeaderDropdown);
    return () => {
      document.removeEventListener('mousedown', closeHeaderDropdown);
    };
  }, [mobileOpenedIndex]);

  useEffect(() => {
    setHeaderOpened(false);
    toggleMobileMenu(mobileOpenedIndex);
  }, [location]);

  useEffect(() => {
    getUserInitials();
  }, [location]);

  function toggleMobileMenu(index) {
    if (width <= 1200 && navLinksRef.current && index > -1) {
      switch (mobileOpenedIndex) {
        case index:
          toggleCollapseElement(navLinksRef.current.children[mobileOpenedIndex].children[1], false);
          setMobileOpenedIndex(-1);
          break;
        case -1:
          toggleCollapseElement(navLinksRef.current.children[index].children[1], true);
          setMobileOpenedIndex(index);
          break;
        default:
          toggleCollapseElement(navLinksRef.current.children[mobileOpenedIndex].children[1], false);
          toggleCollapseElement(navLinksRef.current.children[index].children[1], true);
          setMobileOpenedIndex(index);
      }
    }
  }

  function toggleHeader() {
    setHeaderOpened(val => !val);
    toggleMobileMenu(mobileOpenedIndex);
  }

  function isLogoDark() {
    return width <= 1200 && headerOpened && location.pathname !== '/';
  }

  async function logout() {
    try {
      await logoutUserApi();
      navigate('/');
      setUserId(null);
      setModalVisible(false);
    } catch (error) {
      createNotification(
        'Une erreur est survenue lors de la déconnexion, veuillez réessayer.',
        'var(--red)',
        'var(--grey)',
      );
    }
  }

  function showLogoutModal() {
    setModalContent({
      title: 'Déconnexion',
      content: (
        <>
          <p className='mb-lg'>Êtes-vous sûr de vouloir vous déconnecter ?</p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              onClick={logout}
              padding='10px 25px'
              bgColor='var(--red)'
              color='var(--white)'
              hoverColor='var(--red)'>
              Déconnexion
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  async function getUserInitials() {
    const userInfos = await getUserData();

    if (userInfos && userInfos.first_name && userInfos.last_name)
      setUserInitials(userInfos.first_name.charAt(0).toUpperCase() + userInfos.last_name.charAt(0).toUpperCase());
    else setUserInitials('');
  }

  return (
    <>
      <div className={'header-background ' + className + (headerOpened ? ' header-background-opened ' : '')}>
        <section className={'container header-container ' + (headerOpened ? 'opened-header' : 'closed-header')}>
          <nav className='flex s-between relative'>
            <NavLink to='/' className='logo-container'>
              <EmeLogo
                className='header-logo'
                dark={isLogoDark()}
                typeLogo={width > 1200 ? 1 : 2}
                height={width > 576 ? '60px' : '40px'}
              />
            </NavLink>
            <div className='mobile-header-buttons'>
              <div className='burger-menu' onClick={toggleHeader}>
                <div />
                <div />
                <div />
              </div>
            </div>
            <ul className='navbar-container' ref={navLinksRef}>
              <li>
                <p custom-title='Estimer'>
                  <NavLink to='/detail-parcours/titres-de-societe'>Estimer</NavLink>
                </p>
              </li>
              <li>
                <p custom-title='Activités estimables'>
                  <NavLink to='/liste-activites'>Activités estimables</NavLink>
                </p>
              </li>
            </ul>
            <div className='header-buttons-container' ref={accountNavContainerRef}>
              <div className='account-button-container' onClick={() => setOpenedAccountNav(!openedAccountNav)}>
                <UnfilledButton className='account-button' padding='4px'>
                  {userInitials ? (
                    userInitials
                  ) : (
                    <User color='var(--main-color)' className='header-user-icon' width='22px' />
                  )}
                </UnfilledButton>
                <Bracket
                  rotation={openedAccountNav ? '0' : '180deg'}
                  width='10px'
                  color={location.pathname === '/' ? 'var(--white)' : 'var(--secondary-color)'}
                />
                <ul className='navbar-content account-nav' ref={accountNavRef}>
                  {getUserId() ? (
                    <>
                      <NavLink to='/mon-compte/mes-estimations'>
                        Mes estimations
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                      <NavLink to='/mon-compte/mon-profil'>
                        Mon compte
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                      <NavLink onClick={showLogoutModal} className='header-deconnexion-link'>
                        Déconnexion
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                    </>
                  ) : (
                    <>
                      <NavLink to='/connexion'>
                        Connexion
                        <ArrowSm rotation='180deg' className='navbar-content-arrow' />
                      </NavLink>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </section>
      </div>
      {width <= 1200 && !headerOpened && location.pathname !== '/' && (
        <RoundedDiv rotation='180deg' height='3vw' radius='1600vw' className='header-rounded-div mb-sm' />
      )}
    </>
  );
}

export default Header;
