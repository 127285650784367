import './ComponentsRow.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';
import { isStickyElementPinned } from '../../../utils/Utils';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import Bracket from '../../atoms/icons/general/bracket/Bracket';
import AppContext from '../../../context/AppContext';

// contentType = "letters" | "rapportsExamples" | "estimations" | "formations"

function ComponentsRow({
  componentsProps,
  margin = '0',
  className = '',
  Component,
  contentType = '',
  slideLength = 0,
}) {
  const [fingerStartPosition, setFingerStartPosition] = useState({
    x: 0,
    y: 0,
  });
  const [slidePosition, _setSlidePosition] = useState(0);
  const [scrollHorizontal, setScrollHorizontal] = useState(0);
  const [lastSlideX, setLastSlideX] = useState(0);
  const [sliderDimensions, setSliderDimensions] = useState({
    width: 0,
    childrenCount: 0,
    childWidth: 0,
    padding: 0,
  });
  const [isScrolling, setIsScrolling] = useState(false);
  const [displayedItemsCount, setDisplayedItemsCount] = useState(1);
  const [isSticky, setIsSticky] = useState(false);

  const { width, _ } = useWindowSize();
  const { toggleBodyScroll } = useContext(AppContext);
  const sliderRef = useRef(null);
  const containerRef = useRef();

  useEffect(() => {
    window.addEventListener('scroll', handleStickyScroll);
    return () => {
      window.removeEventListener('scroll', handleStickyScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (sliderRef.current) {
        setSliderDimensions({
          width: sliderRef.current.offsetWidth,
          childrenCount: sliderRef.current.children.length,
          childWidth:
            sliderRef.current.children[0] &&
            sliderRef.current.children[0].getBoundingClientRect().width +
              Number(window.getComputedStyle(sliderRef.current.children[0]).marginLeft.slice(0, -2)) * 2,
          padding: calcPadding(),
        });
      }
    }, 100);

    let tmp = 1;
    switch (contentType) {
      case 'letters':
        tmp = Math.floor(width / 50);
        break;
      case 'estimations':
        tmp = width > 576 ? 2 : 1;
      case 'formations':
        tmp = 1;
    }
    setDisplayedItemsCount(tmp);

    if (contentType === 'rapportsExamples') {
      setTimeout(() => {
        if (sliderRef.current)
          _setSlidePosition(
            sliderRef.current.children[0] &&
              sliderRef.current.children[0].getBoundingClientRect().width +
                Number(window.getComputedStyle(sliderRef.current.children[0]).margin.match(/\d/g).join('')) * 2,
          );
      }, 400);
    }

    if (contentType === 'estimations') _setSlidePosition(0);
  }, [width, componentsProps]);

  useEffect(() => {
    if (sliderRef.current)
      setSliderDimensions({
        ...sliderDimensions,
        width: sliderRef.current.offsetWidth,
      });
  }, [sliderDimensions.padding]);

  function setSlidePosition(value) {
    if (displayedItemsCount === sliderRef.current.children.length) return;

    if (
      value + 5 >= -sliderDimensions.padding / 2 &&
      value - 5 <=
        sliderDimensions.width - sliderDimensions.padding / 2 - sliderDimensions.childWidth * displayedItemsCount
    )
      _setSlidePosition(value);
  }

  function calcPadding() {
    if (!sliderRef.current) return;

    if (contentType === 'formations') return 0;

    const childWidth =
      sliderRef.current.children[0] &&
      sliderRef.current.children[0].getBoundingClientRect().width +
        Number(window.getComputedStyle(sliderRef.current.children[0]).marginLeft.slice(0, -2)) * 2;

    return (width % childWidth) / 2;
  }

  function handleStickyScroll() {
    setIsSticky(isStickyElementPinned(containerRef, 0));
  }

  function handleScroll(e) {
    const fingerPosition =
      e.type === 'mousemove'
        ? {
            x: e.pageX,
            y: e.pageY,
          }
        : {
            x: e.touches[0].screenX,
            y: e.touches[0].screenY,
          };
    const slideX = fingerStartPosition.x - fingerPosition.x;
    const slideY = fingerStartPosition.y - fingerPosition.y;

    switch (scrollHorizontal) {
      case 0:
        if (Math.abs(slideX) > Math.abs(slideY)) {
          setScrollHorizontal(1);
          toggleBodyScroll(false);
        } else setScrollHorizontal(-1);
        break;
      case -1:
        break;
      case 1:
        setScrollHorizontal(1);
        setSlidePosition(slidePosition + lastSlideX - fingerPosition.x);
        setLastSlideX(fingerPosition.x);
    }
  }

  function handleScrollStart(e) {
    sliderRef.current.style.transition = 'none';

    if (e.type === 'mousedown') {
      setFingerStartPosition({ x: e.pageX, y: e.pageY });
      setLastSlideX(e.pageX);
    } else {
      setFingerStartPosition({ x: e.touches[0].screenX, y: e.touches[0].screenY });
      setLastSlideX(e.touches[0].screenX);
    }

    setIsScrolling(true);
  }

  function handleScrollEnd() {
    sliderRef.current.style.transition = 'right 0.3s';

    toggleBodyScroll(true);
    setScrollHorizontal(0);
    setSlidePosition(getClosestArticle(fingerStartPosition.x > lastSlideX));
    setIsScrolling(false);
  }

  function getClosestArticle(upper) {
    let value;
    const maxValue =
      contentType === 'formations'
        ? sliderDimensions.width - sliderDimensions.childWidth
        : sliderDimensions.width - width;

    if (upper)
      value = Math.ceil(slidePosition / sliderDimensions.childWidth + slideLength) * sliderDimensions.childWidth;
    else value = Math.floor(slidePosition / sliderDimensions.childWidth - slideLength) * sliderDimensions.childWidth;

    if (value > maxValue) value = maxValue;

    if (value < 0) value = 0;

    if (lastSlideX === fingerStartPosition.x) value = slidePosition;
    return value;
  }

  function toNextArticle(upper) {
    let value = 0;

    if (upper) value = Math.ceil((slidePosition + 10) / sliderDimensions.childWidth) * sliderDimensions.childWidth;
    else value = Math.floor((slidePosition - 10) / sliderDimensions.childWidth) * sliderDimensions.childWidth;

    setSlidePosition(value);
  }

  function isAtFirstPosition() {
    return slidePosition === 0 || displayedItemsCount >= sliderDimensions.childrenCount;
  }

  function isAtLastPosition() {
    return (
      displayedItemsCount >= sliderDimensions.childrenCount ||
      slidePosition === sliderDimensions.childWidth * (sliderDimensions.childrenCount - displayedItemsCount)
    );
  }

  return (
    <section
      className={
        'components-row-container w-100 ' + (contentType === 'formations' ? 'formations-row ' : '') + className
      }
      style={{ '--margin': margin }}>
      <div className={'components-row ' + (isSticky ? 'components-row-sticky' : '')} ref={containerRef}>
        <div
          className='components-slider'
          style={{ '--slide': slidePosition + 'px', '--padding': sliderDimensions.padding + 'px' }}
          ref={sliderRef}
          onTouchStart={handleScrollStart}
          onTouchMove={handleScroll}
          onTouchEnd={handleScrollEnd}
          onMouseDown={handleScrollStart}
          onMouseMove={e => isScrolling && handleScroll(e)}
          onMouseUp={handleScrollEnd}
          onMouseLeave={handleScrollEnd}>
          {componentsProps.map((props, key) => {
            return <Component key={key} {...props} />;
          })}
        </div>
      </div>
      {contentType === 'formations' && (
        <>
          <FilledButton
            className={'formations-row-bracket ' + (isAtFirstPosition() ? 'd-none' : '')}
            onClick={() => toNextArticle()}
            padding='12px 10px'>
            <Bracket rotation='270deg' marginLeft='0' />
          </FilledButton>
          <FilledButton
            className={'formations-row-bracket ' + (isAtLastPosition() ? 'd-none' : '')}
            onClick={() => toNextArticle(true)}
            padding='12px 10px'>
            <Bracket rotation='90deg' marginLeft='0' />
          </FilledButton>
        </>
      )}
    </section>
  );
}

export default ComponentsRow;
