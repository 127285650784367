import './ListeMaterielPdf.css';
import React, { Fragment } from 'react';

// data = [
//     {
//         id: 1,
//         name: "",
//         description: ""
//     },
// ]

function ListeMaterielPdf({ data }) {
  return (
    <div className='liste-materiel-container'>
      {data?.map(row => (
        <div key={row.id} className='liste-materiel-row'>
          <div className='liste-materiel-item'>
            <span className='liste-materiel-intitule'>
              {row.name.split(' ').map((intitule, key) => (
                <Fragment key={key}>
                  {key > 0 && <>&nbsp;</>}
                  {intitule}
                </Fragment>
              ))}
              &nbsp;:
            </span>
            <span className='liste-materiel-description'>{row.description}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ListeMaterielPdf;
