import './AccountEstimations.css';
import FilledButton from '../../../../../components/molecules/buttons/filledButton/FilledButton';
import Searchbar from '../../../../../components/molecules/searchbar/Searchbar';
import ComponentsRow from '../../../../../components/organisms/componentsRow/ComponentsRow';
import Add from '../../../../../components/atoms/icons/general/add/Add';
import React, { useContext, useEffect, useState } from 'react';
import Download from '../../../../../components/atoms/icons/general/download/Download';
import Pen from '../../../../../components/atoms/icons/monCompte/pen/Pen';
import Archive from '../../../../../components/atoms/icons/monCompte/archive/Archive';
import Unarchive from '../../../../../components/atoms/icons/monCompte/unarchive/Unarchive';
import Duplicate from '../../../../../components/atoms/icons/monCompte/duplicate/Duplicate';
import { useWindowSize } from '@uidotdev/usehooks';
import AppContext from '../../../../../context/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import { archiveEstimationApi, fetchUserEstimationsApi } from '../../../../../api/EstimationApi';
import DuplicateEstimationModal from '../../../../../components/forms/modals/compte/duplicateEstimationModal/DuplicateEstimationModal';
import UnfilledButton from '../../../../../components/molecules/buttons/unfilledButton/UnfilledButton';

function AccountEstimations() {
  const [searchValue, setSearchValue] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [estimations, setEstimations] = useState([]);
  const [visibleEstimations, setVisibleEstimations] = useState([]);
  const [resetSearchValueTrigger, setResetSearchValueTrigger] = useState(false);
  const { getUserId, createNotification, setModalContent, setModalVisible, setAppLoaderVisible } =
    useContext(AppContext);
  const navigate = useNavigate();

  const { width } = useWindowSize();

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchEstimations();
  }, []);

  useEffect(() => {
    setResetSearchValueTrigger(!resetSearchValueTrigger);
  }, [activeTab]);

  useEffect(() => {
    if (estimations.length) setVisibleEstimations(getFilteredEstimations(estimations));
  }, [activeTab, searchValue]);

  async function fetchEstimations() {
    try {
      let res = (await fetchUserEstimationsApi(getUserId())).data;

      res = res['Titres de société'];
      res = res.filter(estimation => estimation != null);
      res.sort((a, b) => stringToDate(b.date_creation_estimation) - stringToDate(a.date_creation_estimation));
      setEstimations(res);
      setVisibleEstimations(getFilteredEstimations(res));

      setAppLoaderVisible(false);
    } catch (error) {
      console.log(error);
      navigate(`/${error.response.status}`);
    }
  }

  function stringToDate(dateString) {
    if (!dateString) return new Date(0);
    var parts = dateString.split(/[\s/:]+/);
    return new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4], parts[5]);
  }

  function getFilteredEstimations(estimations) {
    if (searchValue === '') return estimations.filter(estimation => estimation.archived === !!activeTab);

    return estimations.filter(
      estimation =>
        estimation.archived === !!activeTab &&
        (estimation.infos.enseigne?.toLowerCase().includes(searchValue.toLowerCase()) ||
          estimation.infos.nom?.toLowerCase().includes(searchValue.toLowerCase()) ||
          estimation.type.description.toLowerCase().includes(searchValue.toLowerCase()) ||
          estimation.infos.code_postal.toLowerCase().includes(searchValue.toLowerCase()) ||
          estimation.infos.rue.toLowerCase().includes(searchValue.toLowerCase()) ||
          estimation.infos.ville.toLowerCase().includes(searchValue.toLowerCase())),
    );
  }

  async function archiveEstimation(estimationId, archive) {
    try {
      await archiveEstimationApi(estimationId, archive);
      await fetchEstimations();
      createNotification(<>Votre estimation a été {archive ? 'archivée' : 'désarchivée'} avec succès</>);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors {archive ? "de l'archivage" : 'du désarchivage'} de votre estimation. Veuillez
          réessayer
        </>,
        'var(--red)',
        'var(--grey)',
      );
    }
  }

  function getDuplicateEstimationEnseigne(enseigne) {
    const regex = /#\d+$/;

    if (regex.test(enseigne)) {
      const match = enseigne.match(/\d+$/);

      return enseigne.replace(regex, `#${parseInt(match[0]) + 1}`);
    } else {
      return `${enseigne} #1`;
    }
  }

  function showDuplicateEstimationModal(estimationId, enseigne) {
    setModalContent({
      title: "Dupliquer l'estimation",
      content: (
        <DuplicateEstimationModal
          defaultValue={getDuplicateEstimationEnseigne(enseigne)}
          fetchEstimationsFunction={() => fetchEstimations()}
          estimationId={estimationId}
        />
      ),
    });
    setModalVisible(true);
  }

  function getEstimationDate(estimation) {
    return estimation.date_creation_estimation.substring(0, 10);
  }

  const EstimationTumbnail = estimation => {
    return (
      <div className='estimation-thumbnail-container'>
        <div className='text-center'>Enseigne</div>
        <div className='estimation-thumbnail'>
          <div className='estimation-enseigne'>
            {estimation.infos?.enseigne}
            {estimation.infos?.nom}
          </div>
          <div className='estimation-infos'>
            <p className='label'>Adresse</p>
            <p>{`${estimation.infos.rue} ${estimation.infos.ville}`}</p>
            <p className='label'>Date</p>
            <p>{getEstimationDate(estimation)}</p>
          </div>
          <div className='estimation-buttons'>
            <Link to={`/synthese/${estimation.id}`}>
              <FilledButton color='var(--grey)' bgColor='#E6CC83' hoverColor='#E6CC83'>
                <Pen width='30px' />
              </FilledButton>
              Modifier
            </Link>
            {estimation.validated ? (
              <>
                <Link to={`/detail-parcours/titres-de-societe/${estimation.id}`} target='_blank'>
                  <FilledButton bgColor='#B8C7FF' hoverColor='#B8C7FF'>
                    <Download color='var(--grey)' width='30px' />
                  </FilledButton>
                  Télécharger
                </Link>
                <div onClick={() => showDuplicateEstimationModal(estimation.id, estimation?.infos?.enseigne)}>
                  <FilledButton bgColor='#E3E3E3' hoverColor='#E3E3E3'>
                    <Duplicate width='30px' />
                  </FilledButton>
                  Dupliquer
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className='fake-button'>
                    <Download width='30px' color='var(--grey)' />
                    <div>Estimation non terminée</div>
                  </div>
                  Télécharger
                </div>
                <div>
                  <div className='fake-button'>
                    <Duplicate width='30px' />
                    <div>Estimation non terminée</div>
                  </div>
                  Dupliquer
                </div>
              </>
            )}
            {estimation.archived ? (
              <>
                <div>
                  <FilledButton
                    bgColor='#FDB7A9'
                    hoverColor='#FDB7A9'
                    onClick={() => archiveEstimation(estimation.id, false)}>
                    <Unarchive width='30px' />
                  </FilledButton>
                  Désarchiver
                </div>
              </>
            ) : (
              <div>
                <FilledButton
                  bgColor='#FDB7A9'
                  hoverColor='#FDB7A9'
                  onClick={() => archiveEstimation(estimation.id, true)}>
                  <Archive width='30px' />
                </FilledButton>
                Archiver
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className='container'>
        <div className='estimations-buttons-row'>
          <FilledButton to='/detail-parcours/titres-de-societe' className='mid-page-button'>
            <Add width={width > 576 ? '30px' : '20px'} /> Démarrer une nouvelle estimation
          </FilledButton>
          <UnfilledButton
            className='mid-page-button'
            download
            to={`${process.env.REACT_APP_API_URL}/assets/OutilsPratiques/CONDUIRE_UN_ENTRETIEN_AVEC_L_EXPLOITANT_D_UNE_AFFAIRE.pdf`}>
            <Download color='var(--main-color)' width={width > 576 ? '30px' : '20px'} />
            Télécharger la trame de conduite d'entretien
          </UnfilledButton>
        </div>
        <div className='estimations-container'>
          <div className='estimations-container-header'>
            <p className={activeTab === 0 ? 'active' : ''} onClick={() => setActiveTab(0)}>
              Mes estimations
            </p>
            <p className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
              Mes estimations archivées
            </p>
          </div>
          <div className='search-input-container'>
            <Searchbar
              resetValueTrigger={resetSearchValueTrigger}
              onChange={value => setSearchValue(value)}
              placeholder='Rechercher'
              bgColor={width > 1200 ? 'var(--white)' : 'var(--light-grey)'}
              className='estimations-search-bar'
            />
          </div>
          {width > 1200 && (
            <div className='estimations-table'>
              <div className='estimations-filters'>
                <div>Enseigne</div>
                <div>Adresse</div>
                <div>Date</div>
                <div>Référence</div>
                <div className='estimations-filters-buttons'>
                  <div className='centered' custom-title='Modifier'>
                    <Pen width='23px' />
                  </div>
                  <div className='centered' custom-title='Télécharger'>
                    <Download color='var(--grey)' width='25px' />
                  </div>
                  <div className='centered' custom-title='Dupliquer'>
                    <Duplicate width='25px' />
                  </div>
                  <div className='centered' custom-title={activeTab ? 'Désarchiver' : 'Archiver'}>
                    {activeTab ? <Unarchive width='25px' /> : <Archive width='25px' />}
                  </div>
                </div>
              </div>
              <div className='estimations-table-body'>
                {visibleEstimations.map((estimation, key) => {
                  return (
                    <div className='estimation-overview-container' key={key}>
                      <div>
                        {estimation.infos?.enseigne}
                        {estimation.infos?.nom}
                      </div>
                      <div>{`${estimation.infos.rue} ${estimation.infos.ville}`}</div>
                      <div>{getEstimationDate(estimation)}</div>
                      <div>{estimation.id}</div>
                      <div className='estimations-filters-buttons'>
                        <FilledButton bgColor='var(--light-orange)' to={`/synthese/${estimation.id}`}>
                          Modifier
                        </FilledButton>
                        {estimation.validated ? (
                          <>
                            <FilledButton to={`/pdf/${estimation.id}`} target='_blank' bgColor='var(--light-blue)'>
                              Télécharger
                            </FilledButton>
                            <FilledButton
                              bgColor='#E3E3E3'
                              hoverColor='#E3E3E3'
                              onClick={() => showDuplicateEstimationModal(estimation.id, estimation?.infos?.enseigne)}>
                              Dupliquer
                            </FilledButton>
                          </>
                        ) : (
                          <>
                            <div className='fake-button'>
                              Télécharger<div>Estimation non terminée</div>
                            </div>
                            <div className='fake-button'>
                              Dupliquer<div>Estimation non terminée</div>
                            </div>
                          </>
                        )}
                        {estimation.archived ? (
                          <FilledButton bgColor='#FDB7A9' onClick={() => archiveEstimation(estimation.id, false)}>
                            Désarchiver
                          </FilledButton>
                        ) : (
                          <FilledButton bgColor='#FDB7A9' onClick={() => archiveEstimation(estimation.id, true)}>
                            Archiver
                          </FilledButton>
                        )}
                      </div>
                    </div>
                  );
                })}
                {!visibleEstimations.length && (
                  <p className='text-lg outfit-semibold my-md text-center'>
                    Aucun résultat ne correspond à votre recherche
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      {width <= 1200 && (
        <>
          <ComponentsRow
            slideLength={width > 576 ? 1 : 0}
            contentType='estimations'
            Component={EstimationTumbnail}
            componentsProps={visibleEstimations}
          />
        </>
      )}
    </>
  );
}

export default AccountEstimations;
