import './Parcours.css';
import React, { useEffect, useContext } from 'react';
import ParcoursCarousel from '../organisms/parcoursCarousel/ParcoursCarousel';
import { ParcoursContext } from '../../context/ParcoursContext';
import BilanActif from '../forms/parcours/titresSocietes/step4/BilanActif';
import BilanPassif from '../forms/parcours/titresSocietes/step5/BilanPassif';
import ComptesResultat from '../forms/parcours/titresSocietes/step6/ComptesResultat';
import Retraitements from '../forms/parcours/titresSocietes/step7/Retraitements';

function SecondaryParcours() {
  const { estimationValues, setInputsNames, step, setStepsSummary } = useContext(ParcoursContext);

  useEffect(() => {
    initParcours();
  }, [estimationValues]);

  function initParcours() {
    setInputsNames(['capital_souscrit', 'capitaux_propres', 'vente_marchandise', 'remunerations_dirigeants']);

    setStepsSummary([
      {
        name: `Les chiffres de votre année ${
          estimationValues?.infos?.date_bilan ? estimationValues?.infos?.date_bilan.slice(-4) : ''
        }`,
        steps: [1, 2, 3, 4],
      },
    ]);
  }

  return (
    <>
      <ParcoursCarousel addBottomMargin={step == 2} step={step}>
        <BilanActif secondaryParcours index={1} />
        <BilanPassif secondaryParcours index={2} />
        <ComptesResultat secondaryParcours index={3} />
        <Retraitements secondaryParcours index={4} />
      </ParcoursCarousel>
    </>
  );
}

export default SecondaryParcours;
