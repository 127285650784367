import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Radio from '../../../../molecules/formComponents/radio/Radio';
import Checkbox from '../../../../molecules/formComponents/checkbox/Checkbox';
import Select from '../../../../molecules/formComponents/select/Select';
import Input from '../../../../molecules/formComponents/input/Input';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import { formatDate, getFormattedDate, getSelectValue, removeSpaces } from '../../../../../utils/Utils';
import { fetchDropdownValuesApi } from '../../../../../api/ParcoursApi';
import Tuto from '../../../../atoms/icons/general/tuto/Tuto';

function EtatLocatif({ id, index }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const Form = { watch, register, setValue };
  const {
    showTooltip,
    moveToNextStep,
    step,
    setStep,
    getStepToGo,
    setGoToStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillInputsFromEstimationValues,
    fillSelectsFromEstimationValues,
  } = useContext(ParcoursContext);
  const soumissionBail = watch('soumission_bail');
  const infosComplementaires = watch('infos_complementaires');
  const typeBail = watch('type_bail');
  const [dropdownsValues, setDropdownsValues] = useState([]);

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    if (estimationValues?.step >= index && !estimationValues?.etat_locatif?.montant_loyer_annuel)
      return setValue('soumission_bail', true);

    fillInputsFromEstimationValues(
      'etat_locatif',
      ['montant_loyer_annuel', 'montant_charges_locatives_annuelles', 'montant_taxe_fonciere'],
      setValue,
      estimationValues,
    );
    fillInputsFromEstimationValues(
      'infos_complementaires',
      ['bailleur', 'duree_bail', 'designation_locaux', 'destination_lieux', 'depot_garantie'],
      setValue,
      estimationValues.etat_locatif,
    );
    fillSelectsFromEstimationValues(
      'etat_locatif',
      ['destination_bail_commercial', 'logement_inclus', 'soumission_baux_multiples'],
      setValue,
      estimationValues,
    );
    setValue(
      'date_echeance_bail_commercial',
      formatDate(estimationValues?.etat_locatif?.date_echeance_bail_commercial),
    );
    setValue(
      'revision_sur_les_loyers',
      estimationValues?.etat_locatif?.infos_complementaires?.revision_sur_les_loyers?.id,
    );

    if (estimationValues?.etat_locatif?.infos_complementaires?.bailleur) setValue('infos_complementaires', true);

    setValue('type_bail', estimationValues?.etat_locatif?.type_bail?.id);

    switch (estimationValues?.etat_locatif?.type_bail) {
      case '' || null || undefined:
        break;
      case 'Professionnel':
        setValue('type_bail', '3');
        break;
      case 'Commercial':
        setValue('type_bail', '2');
        break;
      default:
        setValue('type_bail', '1');
        setValue('type_bail_description', estimationValues?.etat_locatif?.type_bail);
        break;
    }
  }, [estimationValues]);

  async function formSubmit(values) {
    const tmp = {
      etat_locatif: {
        soumission_bail: !values.soumission_bail,
        montant_loyer_annuel: !values.soumission_bail ? removeSpaces(values.montant_loyer_annuel) : null,
        montant_charges_locatives_annuelles: !values.soumission_bail
          ? removeSpaces(values.montant_charges_locatives_annuelles)
          : null,
        montant_taxe_fonciere: !values.soumission_bail ? removeSpaces(values.montant_taxe_fonciere) : null,
        date_echeance_bail_commercial: !values.soumission_bail
          ? getFormattedDate(values.date_echeance_bail_commercial)
          : null,
        destination_bail_commercial: !values.soumission_bail
          ? { id: getSelectValue(values.destination_bail_commercial) }
          : null,
        logement_inclus: !values.soumission_bail ? { id: values.logement_inclus } : null,
        soumission_baux_multiples: !values.soumission_bail ? { id: values.soumission_baux_multiples } : null,
        infos_complementaires: {
          bailleur: values.infos_complementaires && !values.soumission_bail ? values.bailleur : null,
          duree_bail: values.infos_complementaires && !values.soumission_bail ? values.duree_bail : null,
          designation_locaux:
            values.infos_complementaires && !values.soumission_bail ? values.designation_locaux : null,
          destination_lieux: values.infos_complementaires && !values.soumission_bail ? values.destination_lieux : null,
          depot_garantie:
            values.infos_complementaires && !values.soumission_bail ? removeSpaces(values.depot_garantie) : null,
          revision_sur_les_loyers:
            values.infos_complementaires && !values.soumission_bail
              ? { id: getSelectValue(values.revision_sur_les_loyers) }
              : null,
        },
      },
    };

    let typeBailValue = '';
    switch (values.type_bail) {
      case '3':
        typeBailValue = 'Professionnel';
        break;
      case '2':
        typeBailValue = 'Commercial';
        break;
      default:
        typeBailValue = values.type_bail_description;
        break;
    }

    tmp.etat_locatif.type_bail = !values.soumission_bail ? typeBailValue : null;

    postValues(tmp);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    const values = {};
    try {
      values.destination_bail_commercial = (await fetchDropdownValuesApi('destination_bail_commercial')).data;
    } catch (error) {}
    try {
      values.revision_sur_les_loyers = (await fetchDropdownValuesApi('revision_sur_les_loyers')).data;
    } catch (error) {}

    setDropdownsValues(values);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <p className='text-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>Etat locatif</h2>
        <Checkbox
          register={register}
          name='soumission_bail'
          label="l'activité n’est pas soumise à un bail commercial, ou l'exploitant détient les murs et ne se verse pas de loyer"
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && e.target.checked) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        <Radio
          visible={!soumissionBail}
          name='type_bail'
          label='type de bail'
          register={register}
          error={errors?.type_bail && errors.type_bail.message}
          content={[
            {
              label: 'Commercial',
              value: 2,
            },
            {
              label: 'Professionnel',
              value: 3,
            },
            {
              label: 'Autre',
              value: 1,
            },
          ]}
        />
        <Input
          visible={typeBail === '1' && !soumissionBail}
          error={errors?.type_bail_description && errors.type_bail_description.message}
          register={register}
          name='type_bail_description'
          label='Précisez'
        />
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            visible={!soumissionBail}
            type='number'
            label={
              <div>
                loyer <span className='outfit-black'>annuel</span> (hors charges)
                <Tuto className='ml-sm' onClick={() => showTooltip('step_11_tuto_1.webp')} />
              </div>
            }
            name='montant_loyer_annuel'
            error={errors?.montant_loyer_annuel && errors.montant_loyer_annuel.message}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            visible={!soumissionBail}
            type='number'
            label={
              <div>
                charges locatives <span className='outfit-black'>annuelles</span>
                <Tuto className='ml-sm' onClick={() => showTooltip('step_11_tuto_2.webp')} />
              </div>
            }
            name='montant_charges_locatives_annuelles'
            error={errors?.montant_charges_locatives_annuelles && errors.montant_charges_locatives_annuelles.message}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            visible={!soumissionBail}
            type='number'
            label={
              <>
                taxe foncière
                <Tuto onClick={() => showTooltip('step_11_tuto_3.webp')} />
              </>
            }
            name='montant_taxe_fonciere'
            error={errors?.montant_taxe_fonciere && errors.montant_taxe_fonciere.message}
            icon='euro'
          />
        </div>
        <Input
          setValue={setValue}
          visible={!soumissionBail}
          type='date'
          label={
            <>
              Date d’échéance du bail commercial
              <Tuto onClick={() => showTooltip('step_11_tuto_4.webp')} />
            </>
          }
          name='date_echeance_bail_commercial'
          error={errors?.date_echeance_bail_commercial && errors.date_echeance_bail_commercial.message}
          register={register}
        />
        <Select
          visible={!soumissionBail}
          label={
            <>
              destination du bail commercial
              <Tuto onClick={() => showTooltip('step_11_tuto_5.webp')} />
            </>
          }
          register={register}
          error={errors?.destination_bail_commercial && errors.destination_bail_commercial.message}
          name='destination_bail_commercial'>
          {dropdownsValues?.destination_bail_commercial?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <div className='row-1000'>
          <Radio
            visible={!soumissionBail}
            label={
              <>
                un logement habitable est-il inclus dans le bail commercial&nbsp;?
                <Tuto onClick={() => showTooltip('step_11_tuto_6.webp')} />
              </>
            }
            name='logement_inclus'
            register={register}
            error={errors?.logement_inclus && errors.logement_inclus.message}
            content={[
              { value: 1, label: 'Non' },
              { value: 2, label: 'Oui' },
            ]}
          />
          <Radio
            visible={!soumissionBail}
            label="l'activité est-elle soumise à plusieurs baux commerciaux&nbsp;?"
            name='soumission_baux_multiples'
            register={register}
            error={errors?.soumission_baux_multiples && errors.soumission_baux_multiples.message}
            content={[
              { value: 1, label: 'Non' },
              { value: 2, label: 'Oui' },
            ]}
          />
        </div>
        <Checkbox
          visible={!soumissionBail}
          label='je souhaite renseigner des informations complémentaires sur le bail commercial'
          name='infos_complementaires'
          register={register}
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && !e.target.checked) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        <div className='row-1000'>
          <Input
            visible={infosComplementaires && !soumissionBail}
            label='Identité du bailleur'
            name='bailleur'
            error={errors?.bailleur && errors.bailleur.message}
            register={register}
          />
          <Input
            register={register}
            name='duree_bail'
            label='Durée du bail'
            error={errors?.duree_bail && errors.duree_bail.message}
            visible={infosComplementaires && !soumissionBail}
          />
        </div>
        <div className='row-1000'>
          <Input
            visible={infosComplementaires && !soumissionBail}
            label={
              <>
                Désignation des locaux
                <Tuto onClick={() => showTooltip('step_11_tuto_7.webp')} />
              </>
            }
            name='designation_locaux'
            error={errors?.designation_locaux && errors.designation_locaux.message}
            register={register}
          />
          <Input
            visible={infosComplementaires && !soumissionBail}
            label={
              <>
                Destination des locaux
                <Tuto onClick={() => showTooltip('step_11_tuto_8.webp')} />
              </>
            }
            name='destination_lieux'
            error={errors?.destination_lieux && errors.destination_lieux.message}
            register={register}
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            icon='euro'
            visible={infosComplementaires && !soumissionBail}
            error={errors?.depot_garantie && errors.depot_garantie.message}
            label={
              <>
                Dépôt de garantie
                <Tuto onClick={() => showTooltip('step_11_tuto_9.webp')} />
              </>
            }
            name='depot_garantie'
            useForm={Form}
          />
          <Select
            visible={infosComplementaires && !soumissionBail}
            label={
              <>
                Révision du loyer
                <Tuto onClick={() => showTooltip('step_11_tuto_10.webp')} />
              </>
            }
            register={register}
            error={errors?.revision_sur_les_loyers && errors.revision_sur_les_loyers.message}
            name='revision_sur_les_loyers'
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}>
            {dropdownsValues?.revision_sur_les_loyers?.map(value => (
              <option key={value.id} value={value.id}>
                {value.description}
              </option>
            ))}
          </Select>
        </div>
      </fieldset>
    </form>
  );
}

export default EtatLocatif;
