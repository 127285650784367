import { useState, useEffect, useContext, Fragment, useRef } from 'react';
import BannerPdf from '../../components/pdfComponents/bannerPdf/BannerPdf';
import PagePdf from '../../components/pdfComponents/pagePdf/PagePdf';
import TablePdf from '../../components/pdfComponents/tablePdf/TablePdf';
import H3Pdf from '../../components/pdfComponents/h3Pdf/H3Pdf';
import StrongWeakPointsGroupPdf from '../../components/pdfComponents/strongWeakPointsGroupPdf/StrongWeakPointsGroupPdf';
import './Pdf.css';
import React from 'react';
import SummaryPdf, { generateHrefPdf } from '../../components/pdfComponents/summaryPdf/SummaryPdf';
import CirclePercentagePdf from '../../components/pdfComponents/circlePercentagePdf/CirclePercentagePdf';
import CalculationDetailsPdf from '../../components/pdfComponents/calculationDetailsPdf/CalculationDetailsPdf';
import EstimationsRangePdf from '../../components/pdfComponents/estimationsRangePdf/EstimationsRangePdf';
import EstimationDetailsPdf from '../../components/pdfComponents/estimationDetailsPdf/EstimationDetailsPdf';
import BarChart from '../../components/pdfComponents/chartPdf/BarChart';
import DoughnutChart from '../../components/pdfComponents/chartPdf/DoughnutChart';
import H1Pdf from '../../components/pdfComponents/h1Pdf/H1Pdf';
import VisitingCard from '../../components/organisms/visitingCard/VisitingCard';
import HrPdf from '../../components/pdfComponents/hrPdf/HrPdf';
import tablesTitles from '../../uploads/PdfData/tablePdf.json';
import elementsExploitationData from '../../uploads/PdfData/elementsExploitationData.json';
import strongWeakPointsData from '../../uploads/PdfData/forces_faiblesses_data.json';
import { useParams } from 'react-router-dom';
import { fetchEstimationApi, fetchEstimationWithSecondaryYearsApi, generatePdfApi } from '../../api/EstimationApi';
import AppContext from '../../context/AppContext';
import DescriptiveText from '../../components/pdfComponents/descriptiveText/DescriptiveText';
import CoverPagePdf from '../../components/pdfComponents/coverPagePdf/CoverPagePdf';
import { fetchDefaultVisitingCardApi } from '../../api/VisitingCardApi';
import ListeMaterielPdf from '../../components/pdfComponents/listeMaterielPdf/ListeMaterielPdf';
import { getRandomString } from '../../utils/Utils';
import estimation_data from '../../uploads/PdfData/estimation_data.json';
import { minify } from 'csso';
import { saveAs } from 'file-saver';
import Download from '../../components/atoms/icons/general/download/Download';
import FilledButton from '../../components/molecules/buttons/filledButton/FilledButton';
import logoCapCession from '../../assets/logos/logo-cap-cession-eme-bleu-orange-fond-blanc-phrase.jpg';
//TODO organisation au sein de l'entreprise
//import OrganizationalChart from '../../components/pdfComponents/chartPdf/OrganizationalChart';
//import SharedAquiredChart from '../../components/pdfComponents/chartPdf/SharedAquiredChart';

function Pdf({
  textColor = '#252B41',
  textSecondaryColor = '#ffffff',
  mainColor = '#1d2362',
  secondaryColor = '#f29554',
  font = 'Roboto',
  borderRadius = '5px',
  tableColor = '51, 51, 51',
  tableSecondaryColor = '#ffffff',
  defaultMargin = '30px',
}) {
  const [mainEstimationId, setMainEstimationId] = useState(); // id de l'estimation principale
  const [estimationYears, setEstimationYears] = useState([]); // années d'estimation
  const [summaryChapters, setSummaryChapters] = useState([]); // sommaire
  const [finalPdfContent, setFinalPdfContent] = useState([]); // contenu final du pdf
  const [finalPdfSections, setFinalPdfSections] = useState([]);
  const [isPdfGenerated, setIsPdfGenerated] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const progressIntervalRef = useRef(null);

  const [rapportData, setRapportData] = useState({});
  const [visitingCard, setVisitingCard] = useState(null); // carte de visite
  const [imagesPaths, setImagesPaths] = useState([]); // image de l'entreprise

  const { estimationIdParam } = useParams(); // id de l'estimation dans l'url
  const { createNotification, getUserId, setAppLoaderVisible, setIsPdfNotificationLoading, isPdfNotificationLoading } =
    useContext(AppContext);

  const [chartActivityMargin, setchartActivityMargin] = useState('');
  const [chartPerformance, setchartPerformance] = useState('');
  const [doughnutImageUrl, setDoughnutImageUrl] = useState('');

  const pdfContainerRef = useRef(null);
  const pdfGeneratorContainerRef = useRef(null);
  const finalPdfContainerRef = useRef(null);
  const paragraphHeightCalcRef = useRef(null);
  const summaryContainerRef = useRef(null);

  const pageHeight = 1010; // hauteur de la page en px
  const maxRowsBySummaryPage = 31; // nombre de lignes max par page du sommaire

  useEffect(() => {
    fetchAllData();
  }, [estimationIdParam]);

  useEffect(() => {
    if (summaryChapters.length) {
      generatePdfWithSummary(finalPdfSections);
    }
  }, [summaryChapters]);

  useEffect(() => {
    addAnchorsOnTitles(finalPdfContainerRef.current);
  }, [finalPdfContent]);

  useEffect(() => {
    generateSummaryFromHtml(finalPdfSections);
  }, [finalPdfSections]);

  useEffect(() => {
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, []);

  // FETCHING DATA FROM API

  async function fetchAllData() {
    setAppLoaderVisible(true);
    await fetchEstimation();
    await fetchVisitingCard();

    setTimeout(() => {
      splitMainPageBreaks();
      setTimeout(() => {
        setAppLoaderVisible(false);
        setTimeout(() => {
          setIsPdfGenerated(true);
        }, 500);
      }, 300);
    }, 1500);
  }

  async function fetchVisitingCard() {
    try {
      const visitingCard = (await fetchDefaultVisitingCardApi(getUserId())).data[0];
      setVisitingCard(visitingCard);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de la carte de visite</>,
        'var(--red)',
        'var(--grey)',
      );
    }
  }

  async function fetchEstimation() {
    try {
      const response = (await fetchEstimationWithSecondaryYearsApi(estimationIdParam)).data; // Récupère les données de l'estimation

      const estimationYears = []; // Tableau des années d'estimation
      const estimationData = (await fetchEstimationApi(response.base_estimation[0].id, true)).data;

      estimationYears.push(estimationData.estimation); // Récupère l'année principale
      for (const secondaryEstimation of response.related_estimations) {
        // Récupère les années secondaires
        estimationYears.push((await fetchEstimationApi(secondaryEstimation.id)).data.estimation);
      }

      estimationYears.forEach(year => {
        // Formatage des données
        year.compte_resultat.vente_marchandise +=
          year.compte_resultat.vente_marchandise_hebergement + year.compte_resultat.vente_marchandise_restauration;
        year.compte_resultat.production_exercice += year.compte_resultat.total_commissions_nettes;

        delete year.compte_resultat.vente_marchandise_hebergement;
        delete year.compte_resultat.vente_marchandise_restauration;
        delete year.compte_resultat.total_commissions_nettes;

        year.ratios.franchise.description = getFranchiseDescription(year);
        year.ratios.e_reputation = getEReputationDescription(year);
        year.ratios.diplome = getBesoinDiplomeDescription(year);
        year.etat_locatif = getEtatLocatifContent(year);

        delete year.etat_locatif.infos_complementaires;
      });

      getImagesPaths(getMainYearEstimation(estimationYears, response.base_estimation[0].id)); // Récupère les images de l'entreprise

      setMainEstimationId(response.base_estimation[0].id); // Définit l'année principale
      setEstimationYears(sortYears(estimationYears)); // Trie les années
      setRapportData(estimationData.rapport_data);
    } catch (error) {
      createNotification(<>Une erreur est survenue lors de la récupération des données</>, 'var(--red)', 'var(--grey)');
    }
  }

  // CONVERTING PREVIEW IN PDF

  async function generatePdf() {
    try {
      setIsPdfNotificationLoading(true);
      setIsButtonLoading(true);

      const globalCssVariables = getRootAndFontFaces();
      const pdfElement = finalPdfContainerRef.current;

      const styles = new Set();
      const elements = pdfElement.querySelectorAll('*');

      for (const element of elements) {
        const cssText = getCssTextForElement(element);
        if (cssText) {
          styles.add(cssText);
        }
      }

      const minifiedStyles = minifyCss([...styles].join(' '));
      const content = `
        <meta charset="UTF-8">
        <style>${globalCssVariables}${minifiedStyles}</style>
        <div class="final-pdf pdf-container">${pdfElement.innerHTML}</div>
      `;

      const fileName = `${
        getBusinessInfos(getMainYearEstimation(estimationYears, mainEstimationId)).name
      }_rapport_estimation.pdf`;

      const userId = getUserId();
      const estimationId = estimationIdParam;

      const response = await generatePdfApi(userId, estimationId, content, fileName);

      const pdfPath = response.data.pdf_path;

      const pdfBlob = await fetch(pdfPath).then(res => res.blob());

      saveAs(pdfBlob, fileName);
      clearInterval(progressIntervalRef.current);
      setIsPdfNotificationLoading(false);
    } catch (error) {
      clearInterval(progressIntervalRef.current);
      createNotification(<>Une erreur est survenue lors de la génération du PDF</>, 'var(--red)', 'var(--grey)');
    } finally {
      setIsPdfNotificationLoading(false);
    }
  }

  async function getBase64FromImageSrc(src) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject; // Ajoutez une gestion des erreurs
      xhr.open('GET', src);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  async function replaceImagesWithBase64(pdfElement) {
    const images = Array.from(document.querySelectorAll('img'));
    const imagePromises = images.map(async image => {
      image.src = await getBase64FromImageSrc(image.src);
    });

    await Promise.all(imagePromises);
  }

  function getRootAndFontFaces() {
    return `
      :root {
        --black: #000;
        --white: #fff;
        --grey: #645e5e;
        --main-color: #1d2362 !important;
        --secondary-color: #f29554 !important;
        --light-grey: #ddd;
        --link-blue: #2727ff;
        --red: #fa5454;
        --green: #3ba216;
        --creamy-white: #fcf9f5;
        --text-color: #252b41;
        --text-secondary-color: #ffffff;
        --table-color: 51, 51, 51;
        --table-secondary-color: #ffffff;
        --main-color: #891147;
        --secondary-color: #6c79a4;
        --font: Roboto !important;
        --border-radius: 5px;
        --default-margin: 30px;
      }
      * {
        font-family: var(--font) !important;
        color: var(--text-color);
        font-variant: normal;
        font-style: normal;
        font-weight: 400;
      }
      @font-face {
        font-family: 'Roboto';
        font-weight: 900;
        font-style: normal;
        src: local('Roboto-Black'), url('https://eme.api.estimermoncommerce.fr/assets/Pdf/media/Roboto-Black.cf56c1b149d0a5e8d7c6.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Roboto';
        font-weight: 700;
        font-style: normal;
        src: local('Roboto-Bold'), url('https://eme.api.estimermoncommerce.fr/assets/Pdf/media/Roboto-Bold.f80816a5455d171f948d.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Roboto';
        font-weight: 400;
        font-style: italic;
        src: local('Roboto-Italic'), url('https://eme.api.estimermoncommerce.fr/assets/Pdf/media/Roboto-MediumItalic.82736aaa11c64709055f.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Roboto';
        font-weight: 500;
        font-style: normal;
        src: local('Roboto-Medium'), url('https://eme.api.estimermoncommerce.fr/assets/Pdf/media/Roboto-Medium.7c8d04cd831df3033c8a.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Roboto';
        font-weight: 300;
        font-style: normal;
        src: local('Roboto-Light'), url('https://eme.api.estimermoncommerce.fr/assets/Pdf/media/Roboto-Light.333da16a3f3cc391d087.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Outfit-Medium';
        src: url('https://eme.api.estimermoncommerce.fr/assets/Pdf/media/Outfit-Medium.fea7d55162ad147275f0.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Outfit-Bold';
        src: url('https://eme.api.estimermoncommerce.fr/assets/Pdf/media/Outfit-Bold.ad38dbc307742e89dcba.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Outfit-Black';
        src: url('https://eme.api.estimermoncommerce.fr/assets/Pdf/media/Outfit-Black.317327217e3fce5fd261.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Outfit-SemiBold';
        src: url('https://eme.api.estimermoncommerce.fr/assets/Pdf/media/Outfit-SemiBold.0458369a9f9af3aa5640.ttf') format('truetype');
      }
      .dots-list li:before,
      .article-container li:before {
        content: '';
        mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik02LjY2NjY4IDEwLjAwMDdDOC41MDc2MyAxMC4wMDA3IDkuOTk5OTkgOC41MDgzIDkuOTk5OTkgNi42NjcyOEM5Ljk5OTk5IDQuODI2MzYgOC41MDc2MyAzLjMzMzk4IDYuNjY2NjggMy4zMzM5OEM0LjgyNTcgMy4zMzM5OCAzLjMzMzMyIDQuODI2MzYgMy4zMzMzMiA2LjY2NzI4QzMuMzMzMzIgOC41MDgzIDQuODI1NyAxMC4wMDA3IDYuNjY2NjggMTAuMDAwN1oiIGZpbGw9IiM4OTExNDciLz4NCjxwYXRoIGQ9Ik02LjY2NjY4IDBDNS4zNDgxMyAwIDQuMDU5MiAwLjM5MDk4OSAyLjk2Mjg3IDEuMTIzNTNDMS44NjY1NSAxLjg1NjA4IDEuMDEyMDYgMi44OTcyNyAwLjUwNzQ3NyA0LjExNTQ0QzAuMDAyODg3NjQgNS4zMzM2MiAtMC4xMjkxMzQgNi42NzQwMyAwLjEyODEwMiA3Ljk2NzI3QzAuMzg1MzM5IDkuMjYwNSAxLjAyMDI4IDEwLjQ0ODQgMS45NTI2MyAxMS4zODA3QzIuODg0OTcgMTIuMzEzMSA0LjA3Mjg3IDEyLjk0OCA1LjM2NjA3IDEzLjIwNTJDNi42NTkyNyAxMy40NjI1IDcuOTk5NyAxMy4zMzA1IDkuMjE3ODggMTIuODI1OUMxMC40MzYxIDEyLjMyMTMgMTEuNDc3MiAxMS40NjY4IDEyLjIwOTggMTAuMzcwNUMxMi45NDIzIDkuMjc0MTcgMTMuMzMzNCA3Ljk4NTIzIDEzLjMzMzQgNi42NjY2OEMxMy4zMzM0IDUuNzkxMTkgMTMuMTYwOSA0LjkyNDI4IDEyLjgyNTkgNC4xMTU0NEMxMi40OTA4IDMuMzA2NiAxMS45OTk4IDIuNTcxNjggMTEuMzgwNyAxLjk1MjYyQzEwLjc2MTcgMS4zMzM1NiAxMC4wMjY4IDAuODQyNTAyIDkuMjE3ODggMC41MDc0NjlDOC40MDkwOCAwLjE3MjQzNiA3LjU0MjE4IDAgNi42NjY2OCAwWk02LjY2NjY4IDExLjMzMzNDNS43NDM3IDExLjMzMzMgNC44NDE0NCAxMS4wNTk2IDQuMDc0MDEgMTAuNTQ2OEMzLjMwNjU4IDEwLjAzNDEgMi43MDg0NCA5LjMwNTIzIDIuMzU1MjQgOC40NTI1QzIuMDAyMDIgNy41OTk3OCAxLjkwOTYxIDYuNjYxNTIgMi4wODk2NyA1Ljc1NjI0QzIuMjY5NzQgNC44NTEgMi43MTQyIDQuMDE5NDggMy4zNjY4NCAzLjM2Njg0QzQuMDE5NDggMi43MTQxOSA0Ljg1MSAyLjI2OTczIDUuNzU2MjUgMi4wODk2N0M2LjY2MTUyIDEuOTA5NiA3LjU5OTc4IDIuMDAyMDEgOC40NTI1IDIuMzU1MjNDOS4zMDUyMyAyLjcwODQ0IDEwLjAzNDEgMy4zMDY1NyAxMC41NDY4IDQuMDc0MDFDMTEuMDU5NiA0Ljg0MTQzIDExLjMzMzMgNS43NDM2OSAxMS4zMzMzIDYuNjY2NjhDMTEuMzMzMyA3LjkwNDM2IDEwLjg0MTcgOS4wOTEzNCA5Ljk2NjU0IDkuOTY2NDdDOS4wOTEzNCAxMC44NDE3IDcuOTA0MzYgMTEuMzMzMyA2LjY2NjY4IDExLjMzMzNaIiBmaWxsPSIjODkxMTQ3IiBmaWxsLW9wYWNpdHk9IjAuNiIvPg0KPC9zdmc+DQo=');
        -webkit-mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik02LjY2NjY4IDEwLjAwMDdDOC41MDc2MyAxMC4wMDA3IDkuOTk5OTkgOC41MDgzIDkuOTk5OTkgNi42NjcyOEM5Ljk5OTk5IDQuODI2MzYgOC41MDc2MyAzLjMzMzk4IDYuNjY2NjggMy4zMzM5OEM0LjgyNTcgMy4zMzM5OCAzLjMzMzMyIDQuODI2MzYgMy4zMzMzMiA2LjY2NzI4QzMuMzMzMzIgOC41MDgzIDQuODI1NyAxMC4wMDA3IDYuNjY2NjggMTAuMDAwN1oiIGZpbGw9IiM4OTExNDciLz4NCjxwYXRoIGQ9Ik02LjY2NjY4IDBDNS4zNDgxMyAwIDQuMDU5MiAwLjM5MDk4OSAyLjk2Mjg3IDEuMTIzNTNDMS44NjY1NSAxLjg1NjA4IDEuMDEyMDYgMi44OTcyNyAwLjUwNzQ3NyA0LjExNTQ0QzAuMDAyODg3NjQgNS4zMzM2MiAtMC4xMjkxMzQgNi42NzQwMyAwLjEyODEwMiA3Ljk2NzI3QzAuMzg1MzM5IDkuMjYwNSAxLjAyMDI4IDEwLjQ0ODQgMS45NTI2MyAxMS4zODA3QzIuODg0OTcgMTIuMzEzMSA0LjA3Mjg3IDEyLjk0OCA1LjM2NjA3IDEzLjIwNTJDNi42NTkyNyAxMy40NjI1IDcuOTk5NyAxMy4zMzA1IDkuMjE3ODggMTIuODI1OUMxMC40MzYxIDEyLjMyMTMgMTEuNDc3MiAxMS40NjY4IDEyLjIwOTggMTAuMzcwNUMxMi45NDIzIDkuMjc0MTcgMTMuMzMzNCA3Ljk4NTIzIDEzLjMzMzQgNi42NjY2OEMxMy4zMzM0IDUuNzkxMTkgMTMuMTYwOSA0LjkyNDI4IDEyLjgyNTkgNC4xMTU0NEMxMi40OTA4IDMuMzA2NiAxMS45OTk4IDIuNTcxNjggMTEuMzgwNyAxLjk1MjYyQzEwLjc2MTcgMS4zMzM1NiAxMC4wMjY4IDAuODQyNTAyIDkuMjE3ODggMC41MDc0NjlDOC40MDkwOCAwLjE3MjQzNiA3LjU0MjE4IDAgNi42NjY2OCAwWk02LjY2NjY4IDExLjMzMzNDNS43NDM3IDExLjMzMzMgNC44NDE0NCAxMS4wNTk2IDQuMDc0MDEgMTAuNTQ2OEMzLjMwNjU4IDEwLjAzNDEgMi43MDg0NCA5LjMwNTIzIDIuMzU1MjQgOC40NTI1QzIuMDAyMDIgNy41OTk3OCAxLjkwOTYxIDYuNjYxNTIgMi4wODk2NyA1Ljc1NjI0QzIuMjY5NzQgNC44NTEgMi43MTQyIDQuMDE5NDggMy4zNjY4NCAzLjM2Njg0QzQuMDE5NDggMi43MTQxOSA0Ljg1MSAyLjI2OTczIDUuNzU2MjUgMi4wODk2N0M2LjY2MTUyIDEuOTA5NiA3LjU5OTc4IDIuMDAyMDEgOC40NTI1IDIuMzU1MjNDOS4zMDUyMyAyLjcwODQ0IDEwLjAzNDEgMy4zMDY1NyAxMC41NDY4IDQuMDc0MDFDMTEuMDU5NiA0Ljg0MTQzIDExLjMzMzMgNS43NDM2OSAxMS4zMzMzIDYuNjY2NjhDMTEuMzMzMyA3LjkwNDM2IDEwLjg0MTcgOS4wOTEzNCA5Ljk2NjU0IDkuOTY2NDdDOS4wOTEzNCAxMC44NDE3IDcuOTA0MzYgMTEuMzMzMyA2LjY2NjY4IDExLjMzMzNaIiBmaWxsPSIjODkxMTQ3IiBmaWxsLW9wYWNpdHk9IjAuNiIvPg0KPC9zdmc+DQo=');
        fill: var(--secondary-color);
        position: absolute;
        height: 14px;
        width: 14px;
        background-color: var(--secondary-color);
        background-repeat: no-repeat;
        left: -21px;
        top: 6px;
      }
      .value-fond-commerce .center-estimation-range-container .estimation-range-value:before {
        content: '';
        height: 3px;
        width: 100%;
        position: absolute;
        bottom: -5px;
        left: 0;
        background: linear-gradient(to left, transparent, var(--main-color), transparent);
      }
      .liste-materiel-container * {
        text-align: left !important;
      }
      .liste-materiel-container td::before {
        content: ': ' !important;
        font-weight: bold !important;
        text-align: left !important;
        left: -4px !important;
      }
    `;
  }

  function getCssTextForElement(element) {
    const sheets = Array.from(document.styleSheets).filter(sheet => {
      try {
        return Array.from(sheet.cssRules).some(rule => element.matches(rule.selectorText));
      } catch (e) {
        return false;
      }
    });

    let cssText = '';

    for (const sheet of sheets) {
      try {
        for (const rule of sheet.cssRules) {
          if (element.matches(rule.selectorText)) {
            cssText += rule.cssText;
          }
        }
      } catch (e) {
        console.warn('Cannot access cssRules for sheet:', sheet, e);
      }
    }

    return cssText;
  }

  function minifyCss(css) {
    return minify(css).css;
  }

  // FETCHING SPECIFIC DATA FROM ESTIMATION

  function getMainYearEstimation(years, mainEstimationId) {
    return years.find(year => year.id === mainEstimationId);
  }

  function getImagesPaths(estimationYear) {
    const result = [];

    for (let image of Object.values(estimationYear?.images)) {
      if (image) {
        result.push(image);
      }
    }
    setImagesPaths(result);
  }

  function getBusinessInfos(estimationYear) {
    const result = {
      name: estimationYear?.infos?.raison_sociale,
      type: estimationYear?.infos?.activite?.description,
      rue: estimationYear?.infos?.rue,
      ville: estimationYear?.infos?.ville,
      siret: estimationYear?.infos?.siret,
      codePostal: estimationYear?.infos?.code_postal,
      apeCode: 'N/A',
      legalRepresentative: 'Nom du représentant légal',
      creationDate: estimationYear?.date_creation_estimation.split(' ')[0],
      capital: formatEuroAmount(estimationYear?.infos?.capital_social),
    };
    return result;
  }

  function getEtatLocatifContent(estimationYear) {
    return { ...estimationYear.etat_locatif };
  }

  function getBesoinDiplomeDescription(estimationYear) {
    return estimationYear.diplome_certification.description === 'Oui'
      ? "L'activité ne nécessite pas un diplôme, une certification, une carte professionnelle ou une licence (hormis Lic III et IV et agréments buraliste)"
      : "L'activité ne nécessite pas un diplôme, une certification, une carte professionnelle ou une licence (hormis Lic III et IV et agréments buraliste)";
  }

  function getFranchiseDescription(estimationYear) {
    return estimationYear.ratios.franchise.montant ? 'Oui' : 'Non';
  }

  function getEReputationDescription(estimationYear) {
    let result = [];
    if (estimationYear.e_reputation.note_google) result.push('Avis Google ' + estimationYear.e_reputation.note_google);
    if (estimationYear.e_reputation.note_tripadvisor)
      result.push('Avis Trip Advisor ' + estimationYear.e_reputation.note_tripadvisor);
    if (estimationYear.e_reputation.note_the_fork)
      result.push('Avis The Fork ' + estimationYear.e_reputation.note_the_fork);
    if (estimationYear.e_reputation.note_booking)
      result.push('Avis Booking ' + estimationYear.e_reputation.note_booking);
    if (estimationYear.e_reputation.note_autres) result.push('Autres ' + estimationYear.e_reputation.note_autres);

    return result.join(', ');
  }

  // FORMATTING DATA FOR PDF

  function formatDataForTable(tableModel, years) {
    const result = {
      thead: [],
      tbody: [],
    };

    for (const intitule of Object.values(tableModel.intitules)) {
      const row = {
        type: intitule.type,
        cells: [intitule.name],
      };

      switch (intitule.key) {
        case 'head':
          years.forEach(year => row.cells.push(year.infos.date_bilan));
          result.thead.push(...row.cells);
          break;
        case 'autres':
        case 'autres_majorations':
          result.tbody.push(...formatDataFromMultipleInputs(tableModel.key, intitule.key, years, intitule.type));
          break;
        default:
          let hasNonNullValues = true;

          years.forEach(year => {
            const value = year[tableModel.key][intitule.key];

            row.cells.push(formatEuroAmount(value));
            if (value !== null && value !== undefined) {
              hasNonNullValues = false;
            }
          });

          if (!hasNonNullValues) {
            result.tbody.push(row);
          }
          break;
      }
    }
    return result;
  }

  function formatDataFromMultipleInputs(parent, key, years, type) {
    const result = [];

    if (!years[0] || !years[0][parent]?.[key]) return [];

    years[0][parent]?.[key].forEach(intitule => {
      const row = {
        type: type,
        cells: [intitule.input],
      };

      years.forEach(year => {
        row.cells.push(formatEuroAmount(year[parent]?.[key].filter(item => item.input === intitule.input)[0].value));
      });
      result.push(row);
    });

    return result;
  }

  function formatDataForTableFromMultipleObjects(tableModel, years) {
    const result = {
      thead: [],
      tbody: [],
    };

    for (const [key, intitule] of Object.entries(tableModel.intitules)) {
      const row = {
        type: intitule.type,
        cells: [intitule.name],
      };

      if (intitule.key === 'head') {
        years.forEach(year => row.cells.push(year.infos.date_bilan));
        result.thead.push(...row.cells);
      } else {
        let hasNonNullValues = true;

        years.forEach(year => {
          const value = year?.[intitule.key1]?.[intitule.key2];

          row.cells.push(formatEuroAmount(value, intitule.name.toUpperCase().includes('TAUX')));
          if (value !== null && value !== undefined) {
            hasNonNullValues = false;
          }
        });

        if (!hasNonNullValues) {
          result.tbody.push(row);
        }
      }
    }
    return result;
  }

  function formatDataForDescriptiveText(paragraphModel, mainYear) {
    const result = [];
    paragraphModel.forEach(group => {
      const row = {
        title: group.title,
        content: [],
      };

      group.intitules.forEach(intitule => {
        if (!mainYear) return;

        let boldText = '';
        const value = mainYear[group.key]?.[intitule.key];
        typeof value === 'object' ? (boldText = value?.description) : (boldText = value);

        if (boldText && value)
          row.content.push({
            normalText: intitule.name,
            boldText: boldText + intitule.suffix,
          });
      });
      if (row.content.length) result.push(row);
    });
    return result;
  }

  function formatDataForRhTable(mainYear) {
    const result = {
      thead: [
        'Personnel',
        'Poste',
        <>
          Date
          <br />
          d'entrée
        </>,
        <>
          Type de
          <br />
          contrat
        </>,
        <>
          Rémunération
          <br />
          annuelle
        </>,
      ],
      tbody: [],
    };
    mainYear?.ratios?.detail_rh?.forEach(rh => {
      result.tbody.push({
        type: rh.is_key ? 2 : 1,
        cells: [rh.nom, rh.poste, rh.annee_entree, rh.type_contrat, formatEuroAmount(rh.salaire_brut_annuel)],
      });
    });

    return result;
  }

  function getActivityMarginChartData(estimationYear) {
    const result = {
      labels: [],
      datasets: [],
    };

    const labels = [];

    estimationYear.forEach(year => {
      labels.push(year.infos.date_bilan);
    });

    result.labels = labels;

    const datasets = [
      {
        label: 'Chiffre d’affaires',
        data: [],
        backgroundColor: 'rgba(253, 207, 96, 1)',
        borderColor: 'rgba(253, 207, 96, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'Marge brute globale',
        data: [],
        backgroundColor: 'rgba(111, 157, 211, 1)',
        borderColor: 'rgba(111, 157, 211, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'Résultat de l’exercice',
        data: [],
        backgroundColor: 'rgba(242, 149, 84, 1)',
        borderColor: 'rgba(242, 149, 84, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
    ];

    estimationYear.forEach(year => {
      datasets[0].data.push(year.compte_resultat.ca_global);
      datasets[1].data.push(year.compte_resultat.marge_brute_globale);
      datasets[2].data.push(year.compte_resultat.resultat_exercice);
    });

    result.datasets = datasets;

    return result;
  }

  function getPerformanceChartData(estimationYears) {
    const result = {
      labels: [],
      datasets: [],
    };

    const labels = [];

    estimationYears.forEach(year => {
      labels.push(year.infos.date_bilan);
    });

    result.labels = labels;

    const datasets = [
      {
        label: "Produits d'exploitation",
        data: [],
        backgroundColor: 'rgba(253, 207, 96, 1)',
        borderColor: 'rgba(253, 207, 96, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'Marge brute globale',
        data: [],
        backgroundColor: 'rgba(111, 157, 211, 1)',
        borderColor: 'rgba(111, 157, 211, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'Valeur ajoutée',
        data: [],
        backgroundColor: 'rgba(242, 149, 84, 1)',
        borderColor: 'rgba(242, 149, 84, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'EBE retraité',
        data: [],
        backgroundColor: 'rgba(236, 102, 8, 1)',
        borderColor: 'rgba(236, 102, 8, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'Résultat net',
        data: [],
        backgroundColor: 'rgba(29, 38, 98, 1)',
        borderColor: 'rgba(29, 38, 98, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
    ];

    estimationYears.forEach(year => {
      datasets[0].data.push(year.compte_resultat.ca_global);
      datasets[1].data.push(year.detail_marge.marge_brute_globale);
      datasets[2].data.push(year.compte_resultat.valeur_ajoutee);
      datasets[3].data.push(year.rentabilite_retraitee.ebe_retraite);
      datasets[4].data.push(year.compte_resultat.resultat_exercice);
    });

    result.datasets = datasets;

    return result;
  }

  function formatEuroAmount(amount, isPercentage = false) {
    if (amount === null || amount === undefined) return '-';
    let result = String(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    result += isPercentage ? ' %' : ' €';

    return result.replaceAll('.', ',');
  }

  function getFinancialDoughnutData(estimationYear) {
    const turnover = estimationYear?.compte_resultat?.ca_global || 0;
    const payroll = formatDataForGraphs(rapportData)?.masseSalariale || 0;
    const rentAndCharges = formatDataForGraphs(rapportData)?.loyerCharges || 0;
    const performanceYear = 'Année N-1 : ' + (estimationYear?.infos?.date_bilan || '').split('/')[2];

    return {
      turnover,
      payroll,
      rentAndCharges,
      performanceYear,
    };
  }

  function getFinancialCirclePercentageData(estimationYear) {
    const percentage = estimationYear?.ratios?.potentiel_ca_3_ans || 0;
    const euroAmount = 'En cours de calcul';

    return {
      percentage,
      euroAmount,
    };
  }

  // à optimiser
  function formatDataForStrongWeakPoints(strongWeakPointsModel, rapportData) {
    if (!rapportData || !rapportData.forces_faiblesses || !strongWeakPointsModel) {
      return { title: '', content: [] };
    }

    const result = {
      title: strongWeakPointsModel?.title || '',
      content: [],
    };

    strongWeakPointsModel.intitules.forEach(intitule => {
      const row = {
        title: intitule?.name || '',
        points: [],
      };

      if (rapportData?.forces_faiblesses[intitule.key]?.force) {
        row.points.push({
          isStrongPoint: true,
          content: rapportData?.forces_faiblesses[intitule.key]?.force,
        });
      }

      if (rapportData?.forces_faiblesses[intitule.key]?.faiblesse) {
        row.points.push({
          isStrongPoint: false,
          content: rapportData?.forces_faiblesses[intitule.key]?.faiblesse,
        });
      }

      if (row.points.length) result.content.push(row);
    });

    if (result.content.length) return result;
    return null;
  }

  function formatDataForGraphs(rapportData) {
    if (!rapportData.graphs) return;

    const result = {};

    result.potentielCA = rapportData?.graphs?.potentiel_ca;

    result.masseSalariale = rapportData?.graphs?.taux_effort_masse_salariale?.masse_salariale;
    result.loyerCharges = rapportData?.graphs?.taux_effort_masse_salariale?.loyer_charges;

    return result;
  }

  function formatDataForEstimationValorisationTable(mainYearDate, values, fdcValue) {
    const total =
      fdcValue +
      values?.valeur_materiel +
      values?.total_immobilisations +
      values?.total_actif_circulant -
      values?.total_dettes;

    return {
      thead: ['Valeurs prises en compte', mainYearDate?.split('/')[2]],
      tbody: [
        {
          type: 1,
          cells: ['Fonds de commerce', formatEuroAmount(fdcValue)],
        },
        {
          type: 1,
          cells: ["+ Matériel d'exploitation", formatEuroAmount(values?.valeur_materiel)],
        },
        {
          type: 1,
          cells: ['+ Immobilisations financières', formatEuroAmount(values?.total_immobilisations)],
        },
        {
          type: 1,
          cells: ['+ Actif circulant', formatEuroAmount(values?.total_actif_circulant)],
        },
        {
          type: 1,
          cells: ['- Dettes', formatEuroAmount(values?.total_dettes)],
        },
        {
          type: 2,
          cells: ['= Valeur des titres de la société *', formatEuroAmount(total)],
        },
      ],
    };
  }

  function formatDataForEstimationValue(values, model) {
    if (!values || !model) return;

    const result = {
      hasTopArrows: true,
      values: [],
    };

    Object.keys(values).forEach(key => {
      if (key !== 'title') result.values.push({ estimation: values[key], description: model[key] });
    });

    return result;
  }

  function formatDataForNegativeValue(description) {
    if (!description) return <></>;
    let tmp = [...description];

    const result = (
      <>
        <div className='default-margin-placeholder' />
        <p>{tmp.shift()}</p>
        <ul className='dots-list'>
          <li className='light'>
            {tmp.shift()}
            <br />
            {tmp.shift()}
            <br />
            {tmp.shift()}
          </li>
          <li className='light'>{tmp.shift()}</li>
        </ul>
        <div className='default-margin-placeholder' />
        <p>
          {' '}
          {tmp.shift()}
          <br />
          {tmp.shift()}
        </p>
        <div className='default-margin-placeholder' />
        <p className='light'>
          {tmp.map((paragraph, index) => (
            <Fragment key={index}>
              {paragraph} <br />
            </Fragment>
          ))}
        </p>
      </>
    );

    console.log('result', result);

    return result;
  }

  // FORMATTING PDF PAGES
  function getHTMLElementsHeight(...elements) {
    let height = 0;
    for (let element of elements) {
      const style = window.getComputedStyle(element);
      height += element.getBoundingClientRect().height + parseFloat(style.marginTop) + parseFloat(style.marginBottom);
    }
    return height;
  }

  function generatePagesFromSections(sections) {
    return sections.map((section, index) => (
      <PagePdf
        key={getRandomString(20)}
        pageActuelle={index ? index + 1 : undefined}
        totalPages={index ? sections.length : undefined}
        innerHTML={section}></PagePdf>
    ));
  }

  function formatRawText(rawText, withMargin = true) {
    return (
      <>
        {withMargin ? <div className='default-margin-placeholder' /> : <> </>}
        <div possible-page-break='true' />
        <p can-page-break='true' className='text-justify'>
          {rawText?.split('\n').map((section, index) => (
            <Fragment key={index}>
              {section}
              <br />
            </Fragment>
          ))}
        </p>
      </>
    );
  }

  function splitMainPageBreaks() {
    if (!pdfGeneratorContainerRef.current) return;

    const sections = pdfGeneratorContainerRef.current.innerHTML.split('<div page-break="true"></div>');
    const onePagePdfContent = generatePagesFromSections(sections);
    setFinalPdfContent(onePagePdfContent);

    setTimeout(() => {
      splitFinalPdfPages();
    }, 300);
  }

  function splitTable(tableElement, allowedHeight) {
    // split table to fit page height
    // calc : table height - table content height + paginator height
    let tableContainerHeight =
      getHTMLElementsHeight(tableElement) - getHTMLElementsHeight(tableElement.children[1]) + 26;
    const separationIndexes = [];
    let index = 0;
    let cumulatedChildrenHeight = tableContainerHeight;

    for (let child of tableElement.children[1].children) {
      if (cumulatedChildrenHeight + getHTMLElementsHeight(child) < allowedHeight) {
        cumulatedChildrenHeight += getHTMLElementsHeight(child);
        index++;
      } else {
        separationIndexes.push(index);
        cumulatedChildrenHeight = tableContainerHeight + getHTMLElementsHeight(child);
        allowedHeight = pageHeight;
        index++;
      }
    }
    separationIndexes.push(index);

    //create sub tables
    const subTables = [];
    let splitRows = Array.from(tableElement.children[1].children).map(row => row.outerHTML);

    for (let i = 0; i < separationIndexes.length; i++) {
      splitRows = splitRows.slice(separationIndexes[i]);
    }
    splitRows = Array.from(tableElement.children[1].children).map(row => row.outerHTML);
    // return false if table can't be split
    if (!separationIndexes[0]) return false;

    for (let i = 0; i < separationIndexes.length; i++) {
      const newTable = tableElement.cloneNode(true);
      newTable.children[1].innerHTML = splitRows.slice(0, separationIndexes[i]).join('');

      splitRows = splitRows.slice(separationIndexes[i]);
      subTables.push(
        newTable.outerHTML + `<div class="table-paginator roboto-italic">${i + 1}/${separationIndexes.length}</div>`,
      );
    }

    return {
      firstTable: subTables[0],
      lastTable: subTables[subTables.length - 1],
      subTables: subTables.slice(1, subTables.length - 1),
      lastTableHeight: cumulatedChildrenHeight + 26,
    };
  }

  function splitParagraph(paragraphElement, allowedHeight) {
    if (!paragraphHeightCalcRef.current) return false;
    const originalContent = paragraphElement.innerHTML;
    paragraphElement.innerHTML = '';
    paragraphHeightCalcRef.current.innerHTML = paragraphElement.outerHTML;
    const textContainer = paragraphHeightCalcRef.current.children[0];
    let content = '';
    const splittedParagraphs = [];

    for (let word of originalContent.split(' ')) {
      content += word + ' ';
      textContainer.innerHTML = content;

      if (getHTMLElementsHeight(textContainer) > allowedHeight) {
        content = content.slice(0, content.length - word.length - 1);
        splittedParagraphs.push(content);
        content = word + ' ';
        allowedHeight = pageHeight;
      }
    }
    splittedParagraphs.push(content);
    textContainer.innerHTML = splittedParagraphs[0];

    for (let i = 0; i < splittedParagraphs.length; i++) {
      paragraphElement.innerHTML = splittedParagraphs[i];
      splittedParagraphs[i] = paragraphElement.outerHTML;
    }

    return {
      firstParagraph: splittedParagraphs.shift(),
      lastParagraph: splittedParagraphs.pop(),
      paragraphs: splittedParagraphs,
      lastParagraphHeight: getHTMLElementsHeight(paragraphHeightCalcRef.current),
    };
  }

  function groupSectionsInPages(htmlSections, JSXSections) {
    const newPages = [];
    let currentPage = [];
    let cumulatedChildrenHeight = 0;

    for (let i = 0; i < JSXSections.length; i++) {
      let sectionHeight = getHTMLElementsHeight(...JSXSections[i]);
      if (sectionHeight + cumulatedChildrenHeight < pageHeight) {
        currentPage.push(htmlSections[i]);
        cumulatedChildrenHeight += sectionHeight;
      } else {
        switch (JSXSections[i][0].localName) {
          //handle tables spliting
          case 'table':
            const splittedTable = splitTable(JSXSections[i][0], pageHeight - cumulatedChildrenHeight);

            if (!splittedTable) {
              newPages.push(currentPage);
              currentPage = [htmlSections[i]];
              cumulatedChildrenHeight = sectionHeight;
            } else {
              currentPage.push(splittedTable.firstTable);
              newPages.push(currentPage, ...[splittedTable.subTables]);
              currentPage = [splittedTable.lastTable];
              cumulatedChildrenHeight = splittedTable.lastTableHeight;
            }
            break;
          //handle paragraphs spliting
          case 'p':
            if (JSXSections[i][0].hasAttribute('can-page-break')) {
              const splittedParagraph = splitParagraph(JSXSections[i][0], pageHeight - cumulatedChildrenHeight);
              currentPage.push(splittedParagraph.firstParagraph);

              newPages.push(currentPage, ...[splittedParagraph.paragraphs]);
              currentPage = [splittedParagraph.lastParagraph];
              cumulatedChildrenHeight = splittedParagraph.lastParagraphHeight;
            } else {
              newPages.push(currentPage);
              currentPage = [htmlSections[i]];
              cumulatedChildrenHeight = sectionHeight;
            }
            break;
          default:
            newPages.push(currentPage);
            currentPage = [htmlSections[i]];
            cumulatedChildrenHeight = sectionHeight;
        }
      }
      sectionHeight = 0;
    }

    if (currentPage.length) newPages.push(currentPage);

    return newPages;
  }

  function splitFinalPdfPages() {
    if (!finalPdfContainerRef.current) return;
    const result = [];

    for (let pageIndex = 0; pageIndex < finalPdfContainerRef.current.children.length; pageIndex++) {
      const pageContent = finalPdfContainerRef.current.children[pageIndex].children[0];
      let childrenHeight = getHTMLElementsHeight(...pageContent.children);

      //check if page content height exceeds page height
      if (childrenHeight < pageHeight) {
        result.push([pageContent.innerHTML]);
        continue;
      }

      //split page content into multiple possible page breaks (HTML then JSX)
      const sortedJSXSections = [[]];
      const htmlSections = pageContent.innerHTML
        .split('<div possible-page-break="true"></div>')
        .filter(section => section.length);

      for (let i = 0; i < pageContent.children.length; i++) {
        if (pageContent.children[i].getAttribute('possible-page-break') === 'true') {
          if (sortedJSXSections[sortedJSXSections.length - 1].length) sortedJSXSections.push([]);
        } else {
          sortedJSXSections[sortedJSXSections.length - 1].push(pageContent.children[i]);
        }
      }
      if (sortedJSXSections[sortedJSXSections.length - 1].length === 0) sortedJSXSections.pop();

      //rebuild new pages with sections
      const newPages = groupSectionsInPages(htmlSections, sortedJSXSections);
      result.push(...newPages.map(section => [section.join('')]));
    }
    setFinalPdfSections(result.filter(section => section[0].length));
  }

  function generateSummaryFromHtml(sections) {
    let summary = [];

    //check on how many pages summary will be displayed
    let rowsCount = 0;
    let pagesCount = 1;
    for (let pageIndex = 0; pageIndex < sections.length; pageIndex++) {
      const parser = new DOMParser();
      const sectionHtml = parser.parseFromString(sections[pageIndex][0], 'text/html');
      for (const element of sectionHtml.children[0].children[1].children) {
        if (
          (element.hasAttribute('h2-title') || element.hasAttribute('h3-title')) &&
          !element.hasAttribute('not-on-summary')
        ) {
          if (rowsCount >= maxRowsBySummaryPage) {
            pagesCount++;
            rowsCount = 0;
          }
          rowsCount++;
        }
      }
    }

    for (let pageIndex = 0; pageIndex < sections.length; pageIndex++) {
      const parser = new DOMParser();
      const sectionHtml = parser.parseFromString(sections[pageIndex][0], 'text/html');

      for (const element of sectionHtml.children[0].children[1].children) {
        if (element.hasAttribute('h2-title') && !element.hasAttribute('not-on-summary')) {
          summary.push({
            title: element.innerText,
            page: pageIndex + pagesCount + 1,
            subItems: [],
          });
        }
        if (element.hasAttribute('h3-title') && !element.hasAttribute('not-on-summary')) {
          summary.at(-1).subItems.push({
            subtitle: element.innerText,
            page: pageIndex + pagesCount + 1,
          });
        }
      }
    }
    setSummaryChapters(summary);
  }

  // last step of PDF generation
  function generatePdfWithSummary(sections) {
    if (!summaryContainerRef.current) return;
    const htmlSections = summaryContainerRef.current.innerHTML
      .split('<div possible-page-break="true"></div>')
      .filter(section => section.length);

    const JSXSections = [[]];

    for (let i = 0; i < summaryContainerRef.current.children.length; i++) {
      if (summaryContainerRef.current.children[i].getAttribute('possible-page-break') === 'true') {
        if (JSXSections[JSXSections.length - 1].length) JSXSections.push([]);
      } else {
        JSXSections[JSXSections.length - 1].push(summaryContainerRef.current.children[i]);
      }
    }
    if (JSXSections[JSXSections.length - 1].length === 0) JSXSections.pop();

    const summaryPages = groupSectionsInPages(htmlSections, JSXSections);
    setFinalPdfContent([
      ...generatePagesFromSections([
        sections[0],
        ...summaryPages.map(section => [section.join('')]),
        ...sections.slice(1),
      ]),
    ]);

    setTimeout(async () => {
      await replaceImagesWithBase64();
      setIsButtonDisabled(false);
      setIsButtonLoading(false);
    }, 500);
  }

  function addAnchorsOnTitles(element) {
    if (
      (element.hasAttribute('h2-title') || element.hasAttribute('h3-title')) &&
      !element.hasAttribute('not-on-summary')
    ) {
      element.setAttribute('id', generateHrefPdf(element.innerText));
    }

    for (let child of element.children) {
      addAnchorsOnTitles(child);
    }
  }

  // MISC

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function sortYears(years) {
    return years.sort((a, b) => {
      const [dayA, monthA, yearA] = a?.infos?.date_bilan.split('/');
      const [dayB, monthB, yearB] = b?.infos?.date_bilan.split('/');
      return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
    });
  }

  // CREATING COMPONENTS DATA

  /*
  const shareholders = [
    {
      name: 'Philippe Durand',
      role: 'Président',
      percentage: '50%',
    },
    {
      name: 'Sophie Durand',
      role: 'Directeur général',
      percentage: '50%',
    },
  ];

  const subsidiaries = [
    {
      name: 'SARL A',
      siren: '878 066 455',
      percentage: '50%',
    },
    {
      name: 'SARL B',
      siren: '878 066 456',
      percentage: '50%',
    },
  ];
  */
  return (
    <section
      className={`flex ${isPdfGenerated ? 'pdf-generated' : ''}`}
      style={{
        '--text-color': textColor,
        '--text-secondary-color': textSecondaryColor,
        '--table-color': tableColor,
        '--table-secondary-color': tableSecondaryColor,
        '--main-color': mainColor,
        '--secondary-color': secondaryColor,
        '--font': font,
        '--border-radius': borderRadius,
        '--default-margin': defaultMargin,
      }}>
      {/* paragraphs height calculator */}
      <div className='generator-container pdf-container'>
        <div ref={paragraphHeightCalcRef} className='page-pdf' />
      </div>

      {/* Summary generator */}
      <div className='generator-container'>
        <PagePdf>
          <div ref={summaryContainerRef}>
            <BannerPdf title='Sommaire' notOnSummary />
            <div className='default-margin-placeholder' />
            <SummaryPdf summaryChapters={summaryChapters} />
          </div>
        </PagePdf>
      </div>

      {/* PDF generator */}
      <div className='pdf-container generator-container' ref={pdfContainerRef}>
        <div className='pdf-generator-container' ref={pdfGeneratorContainerRef}>
          <CoverPagePdf
            logoUrl={logoCapCession}
            reportTitle="Rapport d'estimation"
            businessInfo={getBusinessInfos(getMainYearEstimation(estimationYears, mainEstimationId))}
          />
          <div page-break='true' />
          <BannerPdf title='Préambule' />
          <p className='pdf-p'>
            Ce rapport a pour objectif d'évaluer l'entreprise sur la base{' '}
            <span style={{ fontWeight: '700' }}>
              d'éléments financiers, ainsi que toutes les facettes de l'exploitation,
            </span>{' '}
            incluant ses forces et faiblesses.
          </p>
          <p className='pdf-p'>
            Cette estimation a été réalisée avec une <span style={{ fontWeight: '700' }}>vision opérationnelle</span>,
            en intégrant chiffre d'affaires, rentabilité, rayonnement, état locatif, dynamique commerciale, aspects
            logistiques, ratios clefs, valeur ajoutée de l'exploitant et éléments d'actif et passif du bilan.
          </p>
          <p className='pdf-p'>
            L'évaluation d'une entreprise est étroitement liée à sa{' '}
            <span style={{ fontWeight: '700' }}>potentialité de transmission.</span>
          </p>
          <p className='pdf-p'>
            L'expérience du terrain nous a appris que la valeur d'une affaire ne tient pas qu'à des ratios comptables ou
            aux seuls souhaits du cédant.
          </p>
          <p className='pdf-p'>
            Elle doit constituer un juste équilibre entre ceux-ci et de nombreux autres facteurs, et assurer la{' '}
            <span style={{ fontWeight: '700' }}>finançabilité du projet</span> pour de potentiels repreneurs.
          </p>
          <p className='pdf-p'>
            Une affaire peut être transmise sous forme de fonds de commerce ou de titres de société.
          </p>
          <p className='pdf-p'>Ce rapport présente l'évaluation de l'entreprise en 4 parties :</p>
          <ul className='dots-list'>
            <li>Les éléments financiers et leur retraitement</li>
            <li>Les éléments d'exploitation</li>
            <li>L'évaluation</li>
            <li>Les annexes</li>
          </ul>
          <p className='pdf-p'>
            La fourchette de valeurs indiquée dans ce rapport ne constitue qu'une{' '}
            <span style={{ fontWeight: '700' }}>base de départ dans un éventuel projet de transmission</span>, dont la
            valeur finale négociée entre cédant et repreneur peut être très différente.
          </p>
          <p className='pdf-p'>
            Le présent rapport et son contenu n'ont aucune valeur juridique. Sa nature est purement informative.
          </p>
          <div page-break='true' />
          {!!imagesPaths.length && (
            <>
              <BannerPdf title={`Visuel${imagesPaths.length > 1 ? 's' : ''}`} />
              {imagesPaths.map((imagePath, key) => (
                <Fragment key={imagePath}>
                  <div className='pdf-business-image-container'>
                    <img src={imagePath} className='pdf-business-img' alt={`Photo de l'affaire ${key + 1}`} />
                  </div>
                  <div possible-page-break='true' />
                </Fragment>
              ))}
            </>
          )}
          {/*
          <div page-break='true' />
          <BannerPdf title='Organigramme' />
          <H3Pdf title='Actionnaires et bénéficiaires effectifs' className='mt-lg' />
          <OrganizationalChart shareholders={shareholders} />
          <div page-break='true' />
          <H3Pdf title="Titres détenus par l'entreprise" />
          <SharedAquiredChart
            companyName={getMainYearEstimation(estimationYears, mainEstimationId)?.infos?.enseigne}
            subsidiaries={subsidiaries}
          />
          */}
          <div page-break='true' />
          <BannerPdf title='Éléments financiers' />
          <H3Pdf title='Bilan Actif' className='mt-lg' />
          <TablePdf data={formatDataForTable(tablesTitles.bilan_actif, estimationYears)} />
          {formatRawText(getMainYearEstimation(estimationYears, mainEstimationId)?.bilan_actif?.note_bilan_actif)}
          <div page-break='true' />
          <H3Pdf title='Bilan Passif' />
          <TablePdf data={formatDataForTable(tablesTitles.bilan_passif, estimationYears)} />
          <H3Pdf title='Détail des dettes hors-exploitation' className='mt-lg' />
          <TablePdf data={formatDataForTable(tablesTitles.dettes, estimationYears)} />
          <div possible-page-break='true' />
          {formatRawText(
            getMainYearEstimation(estimationYears, mainEstimationId)?.dettes_hors_exploitation
              ?.note_dettes_hors_exploitation,
          )}
          <div page-break='true' />
          <H3Pdf title='Compte de résultat' />
          <TablePdf data={formatDataForTable(tablesTitles.compte_resultat, estimationYears)} />
          {formatRawText(
            getMainYearEstimation(estimationYears, mainEstimationId)?.compte_resultat?.note_compte_resultat,
          )}
          <div page-break='true' />
          <H3Pdf title="Détail de l'activité et de la marge" />
          <TablePdf data={formatDataForTableFromMultipleObjects(tablesTitles.activite_marge, estimationYears)} />
          <BarChart data={getActivityMarginChartData(estimationYears)} onImageReady={setchartActivityMargin} />
          {chartActivityMargin && (
            <img
              src={chartActivityMargin}
              style={{ width: '100%', height: '350px' }}
              alt="Graphique en barre pour l'activité et la marge"
            />
          )}
          {formatRawText(getMainYearEstimation(estimationYears, mainEstimationId)?.detail_marge?.note_detail_marge)}
          <div page-break='true' />
          <H3Pdf title='Retraitements' />
          <TablePdf data={formatDataForTable(tablesTitles.retraitements_remunerations, estimationYears)} />
          <TablePdf data={formatDataForTable(tablesTitles.retraitements_autres_charges, estimationYears)} />
          {formatRawText(
            getMainYearEstimation(estimationYears, mainEstimationId)?.retraitements_autres_charges
              ?.note_retraitements_autres_charges,
          )}
          <div page-break='true' />
          <H3Pdf title='Récapitulatif de la rentabilité retraitée' />
          {formatRawText(
            getMainYearEstimation(estimationYears, mainEstimationId)?.rentabilite_retraitee?.note_rentabilite_retraitee,
          )}
          <TablePdf data={formatDataForTableFromMultipleObjects(tablesTitles.rentabilite_retraitee, estimationYears)} />
          <div page-break='true' />
          <H3Pdf title='Les indicateurs de performance' />
          <BarChart
            data={getPerformanceChartData(estimationYears)}
            onImageReady={setchartPerformance}
            verticalText={true}
          />
          {chartPerformance && (
            <img
              src={chartPerformance}
              style={{ width: '100%', height: '350px' }}
              alt="Graphique en barre pour l'indication de performance"
            />
          )}
          <div page-break='true' />
          <BannerPdf title="Eléments d'exploitation" />
          {formatDataForDescriptiveText(
            elementsExploitationData,
            getMainYearEstimation(estimationYears, mainEstimationId),
          ).map(group => (
            <DescriptiveText key={group.title} data={group} />
          ))}
          <div possible-page-break='true' />
          {getMainYearEstimation(estimationYears, mainEstimationId)?.ratios?.detail_rh?.length > 0 && (
            <>
              <H3Pdf title='Ressources humaines' className='mt-md' />
              <TablePdf data={formatDataForRhTable(getMainYearEstimation(estimationYears, mainEstimationId))} />
              <div className='rh-table-legend'>
                <div /> Hommes clefs
              </div>
            </>
          )}
          <div page-break='true' />
          <BannerPdf title="L'estimation" />
          {formatRawText(rapportData?.calcs?.methode_valorisation?.valeur?.title)}
          {rapportData?.calcs?.methode_valorisation?.type === 'negative' ? (
            formatDataForNegativeValue(rapportData?.calcs?.methode_valorisation?.valeur?.description)
          ) : (
            <>
              <EstimationsRangePdf
                data={formatDataForEstimationValue(
                  rapportData?.calcs?.methode_valorisation?.valeur,
                  estimation_data.valeur.descriptions,
                )}
                type={rapportData?.calcs?.methode_valorisation?.type}
              />
              <H3Pdf title="Détail de l'estimation" className='mt-md' />
              <p className='mt-sm text-justify'>
                Cette valeur est une moyenne des prises en compte de différents indicateurs, ayant abouti aux valeurs
                suivantes :
              </p>
              <EstimationDetailsPdf data={rapportData?.calcs?.methode_valorisation?.valeur_calcul} />
            </>
          )}
          <div page-break='true' />
          <H1Pdf fontSize='76px' title='Annexes' />
          <div page-break='true' />
          {visitingCard && <VisitingCard {...visitingCard} className='pdf-visiting-card' />}
          {visitingCard?.mentions && (
            <>
              <H3Pdf title='Mentions légales' className='mt-lg' />
              <p className='mt-sm'>{visitingCard.mentions}</p>
            </>
          )}
          <div page-break='true' />
          <H3Pdf title='Valeur du fonds de commerce' className='mt-md' />
          <div className='mt-sm' />
          {formatRawText(rapportData?.calcs?.methode_valorisation?.valeur_fdc?.title, false)}
          <EstimationsRangePdf
            data={formatDataForEstimationValue(
              rapportData?.calcs?.methode_valorisation?.valeur_fdc,
              estimation_data.valeur_fdc.descriptions,
            )}
            type={rapportData?.calcs?.methode_valorisation?.type}
            className='value-fond-commerce'
          />
          <H3Pdf title='Détail du calcul' className='mt-md' />
          <CalculationDetailsPdf details={rapportData?.calcs?.methode_valorisation?.valeur_calcul_fdc} />
          <div page-break='true' />
          <p className='text-justify mt-md'>
            A partir de la valeur du fonds de commerce, la valeur des titres de la société{' '}
            <span className='bold'>
              {getMainYearEstimation(estimationYears, mainEstimationId)?.infos?.enseigne.toUpperCase()}
            </span>{' '}
            a été calculée de la manière suivante :
          </p>
          <TablePdf
            data={formatDataForEstimationValorisationTable(
              getMainYearEstimation(estimationYears, mainEstimationId)?.infos?.date_bilan,
              rapportData?.calcs?.methode_valorisation?.valeurs_financieres,
              rapportData?.calcs?.methode_valorisation?.valeur_fdc?.mid_amount,
            )}
            className='mt-sm'
          />
          <p className='text-asterisk-valeurs-tds'>
            * En fonction des conditions de transmission, il convient d'ajouter à cette valeur le remboursement des
            comptes courants d'associés (voir page suivante).
          </p>
          <div page-break='true' />
          <BannerPdf title="Notes sur l'estimation" />
          <p className='pdf-p'>
            Les fourchettes de valeur sont calculées à partir de la totalité des facteurs financiers et non-financiers
            de l'exploitation. Chacun de ces facteurs impacte l'évaluation globale.
          </p>
          <H3Pdf title='Les comptes courants d’associés' className='mt-md' />
          <p className='mt-sm text-justify'>
            Cette estimation est valable avec comme date de référence le bilan dont vous avez renseigné les éléments
            d'actif et de passif. Ces éléments, lorsqu'ils évolueront, impacteront la valeur des titres de la société.
          </p>
          <p className='mt-sm text-justify bolder'>
            Dans le cadre d'une cession et en fonction de la négociation, un repreneur potentiel, en plus du prix des
            titres de société indiqué plus haut, sera susceptible de devoir rembourser au cédant (sauf s'il les
            abandonne partiellement ou totalement) ses comptes courants d'associés d'un montant de{' '}
            {formatEuroAmount(
              getMainYearEstimation(estimationYears, mainEstimationId)?.bilan_passif?.comptes_courants_associes,
            )}
            .
          </p>
          <H3Pdf title='Les stocks' className='mt-md' />
          <p className='mt-sm text-justify'>
            La valeur des stocks réels au jour de la cession est incluse dans la valeur finale des titres de société.
          </p>
          <H3Pdf title='La moyenne de chiffre d’affaires annuel HT' className='mt-md' />
          <p className='mt-sm text-justify'>
            <span style={{ color: 'var(--main-color)' }}>(1)</span> La moyenne des chiffre d'affaire HT traduit le
            volume d'affaires que l'exploitation a été (N-1 et N-2) ou sera (N et N+1) capable de réaliser. Cet
            indicateur est soumis à un coefficient multiplicateur dépendant à la fois du type d'activité et de
            l'ensemble des facteurs (implantation, attributs, ratios-clés, etc.), qui jouent un rôle pondérateur (à la
            hausse ou à la baisse, selon).
          </p>
          <H3Pdf title='La moyenne de rentabilité retraitée' className='mt-md' />
          <p className='mt-sm text-justify'>
            <span style={{ color: 'var(--main-color)' }}>(2)</span> La moyenne des EBE retraités traduit la moyenne des
            rentabilités réelles annuelles dégagées par l'exploitation, et donc sa finançabilité. L'on adopte ainsi
            l'approche d'un repreneur potentiel, qui devra à la fois financer la reprise de l’affaire et dégager une
            rémunération pour tirer un revenu de l'exploitation. Cet indicateur est soumis à un pourcentage spécifique à
            chaque activité et ajusté par l'ensemble des facteurs pondérateurs.
          </p>
          <div page-break='true' />
          <H3Pdf title='Le taux de profitabilité' />
          <p className='mt-sm text-justify'>
            <span style={{ color: 'var(--main-color)' }}>(3)</span> Le taux de profitabilité met en perspective la
            rentabilité moyenne de l'affaire (EBE retraité) par rapport à son chiffre d'affaires moyen et détermine
            l'attractivité financière de l'affaire. On valorise donc mieux une affaire dégageant une meilleure
            rentabilité qu'une autre affaire à chiffre d'affaires égal, ou dégageant une rentabilité égale pour un
            chiffre d'affaires inférieur.
          </p>
          <H3Pdf title='Une estimation n’est pas un prix de vente' className='mt-md' />
          <p className='mt-sm text-justify'>
            Dans un projet de cession de l'affaire, il est fondamental de prendre en compte différentes approches
            complémentaires. La valeur finale (prix effectif de cession) est le point de rencontre entre cédant et
            repreneur (et ses conseils comptables juridiques et financiers, dont l'avis compte dans la validation du
            prix).
          </p>
          <p className='mt-sm text-justify'>
            Dans un projet autre qu'une transaction, l'exploitant, bien que conseillé et encadré par des normes fiscales
            et de bon sens, reste seul décisionnaire de la valeur de son bien et se situera plus librement dans les
            fourchettes de valeurs.
          </p>
          <div page-break='true' />
          <BannerPdf title="Taux d'effort & masse salariale" />
          <DoughnutChart
            turnover={getFinancialDoughnutData(getMainYearEstimation(estimationYears, mainEstimationId)).turnover}
            payroll={getFinancialDoughnutData(getMainYearEstimation(estimationYears, mainEstimationId)).payroll}
            rentAndCharges={
              getFinancialDoughnutData(getMainYearEstimation(estimationYears, mainEstimationId)).rentAndCharges
            }
            performanceYear={
              getFinancialDoughnutData(getMainYearEstimation(estimationYears, mainEstimationId)).performanceYear
            }
            onImageReady={setDoughnutImageUrl}
          />
          {doughnutImageUrl && (
            <div className='doughnut-chart'>
              <img src={doughnutImageUrl} alt='Graphique en donut pour la performance' />
            </div>
          )}
          <H3Pdf title="Le taux d'effort" className='mt-md' />
          <p className='mt-sm text-justify'>
            Il représente le poids de la charge locative globale (loyers + charges + taxe foncière + droits de voirie)
            par rapport au chiffre d'affaires que réalise l'exploitation.
          </p>
          <p className='mt-sm text-justify'>
            En fonction de la dépendance de l'activité à un local commercial, cet indicateur peut être un facteur
            décisionnel important pour le repreneur potentiel, car le loyer est une charge incompressible d'une part, et
            en hausse constante d'autre part.
          </p>
          <H3Pdf title='Le taux de masse salariale' className='mt-md' />
          <p className='mt-sm text-justify'>
            Il représente le poids des salaires (salaires bruts + charges sociales) des employés par rapport au chiffre
            d'affaires que réalise l'exploitation.
          </p>
          <p className='mt-sm text-justify'>
            C'est un facteur décisionnel important pour le repreneur potentiel, qui devra prévoir une masse salariale
            cohérente pour réaliser un chiffre d'affaires au moins équivalent au vôtre, en tenant compte du poste qu'il
            occupera, de son expérience et de ses compétences.
          </p>
          <div page-break='true' />
          <BannerPdf title='Potentiel de croissance estimé sur 3 ans' />
          <CirclePercentagePdf
            percentage={
              getFinancialCirclePercentageData(getMainYearEstimation(estimationYears, mainEstimationId)).percentage
            }
            euroAmount={formatDataForGraphs(rapportData)?.potentielCA}
            percentageText="Pourcentage estimé d'évolution des produits d'exploitation sur 3 ans"
            euroAmountText="Montant estimé d'évolution des produits d'exploitation sur 3 ans"
          />
          <p className='mt-sm'>
            Le potentiel de croissance moyenne estimé est conséquent et de nature à résolument motiver un repreneur
            potentiel, dans la mesure où les pistes de croissance sont clairement identifiées et réalistes. Considérant
            la perte naturelle d'une partie de la clientèle lors de la transmission, le repreneur dispose toutefois
            d'une marge de progression solide.
          </p>
          <div page-break='true' />
          <BannerPdf title="Points forts et points faibles de l'affaire" />
          {strongWeakPointsData?.map(group => (
            <StrongWeakPointsGroupPdf key={group.title} data={formatDataForStrongWeakPoints(group, rapportData)} />
          ))}
          <div page-break='true' />
          {getMainYearEstimation(estimationYears, mainEstimationId)?.materiel_agencements?.liste_materiel?.length >
            0 && (
            <>
              <BannerPdf title='Liste du matériel' />
              <ListeMaterielPdf
                data={getMainYearEstimation(estimationYears, mainEstimationId)?.materiel_agencements?.liste_materiel}
              />
            </>
          )}
          <div page-break='true' />
          <BannerPdf title='Précautions quant à la présente estimation' />
          <p className='mt-sm text-justify'>
            Un certain nombre de facteurs peuvent impacter la concordance entre le résultat de <br />
            l'estimation et le futur prix effectif de cession de l'affaire :
          </p>
          <H3Pdf title='Eléments inhérents à l’affaire' className='mt-md' />
          <p className='mt-sm text-justify'>Tels que :</p>
          <ul className='dots-list'>
            <li>L'attrait que représente celle-ci pour des acquéreurs potentiels</li>
            <li>L'impératif de cession</li>
            <li>
              Plus globalement d'éventuels atouts ou défauts fortement disruptifs de l'affaire ou du <br />
              bien par rapport à des normes classiques de marché
            </li>
          </ul>
          <H3Pdf title='Eléments externes à l’affaire' className='mt-md' />
          <p className='mt-sm text-justify'>Tels que :</p>
          <ul className='dots-list'>
            <li>Les fluctuations des paramètres du marché</li>
            <li>Les prix de cession effectifs</li>
            <li>
              Plus globalement d'éventuels paramètres géographiques, conjoncturels, sociaux ou <br />
              métier, qui seraient fortement disruptifs par rapport à des normes classiques de marché
            </li>
          </ul>
          <HrPdf />
          <p className='mt-sm text-justify'>
            La fixation du prix que le vendeur pense être en mesure d'obtenir dans le cadre d'une cession relève de son
            entière décision. Pour cela il est conseillé au vendeur, outre se baser sur le résultat d'estimation fourni
            par ce rapport, de se rapprocher de ses conseils habituels, ainsi que de faire appel à sa propre perception
            du marché et de la valeur de son bien.
          </p>
          <p className='mt-sm text-justify' style={{ color: 'var(--main-color)' }}>
            Rappelez-vous toujours une règle d’or en affaire : au-delà de toutes les estimations possibles, le prix
            final est celui où se rejoignent acquéreur et cédant.
          </p>
          <p className='mt-sm text-justify'>
            Le prix effectif de vente des titres de société ne pourra en aucun cas nous être opposé.
          </p>
          <div page-break='true' />
          <H3Pdf title='La valeur indiquée dans ce rapport diffère de vos attentes ?' />
          <span className='mt-sm text-justify' style={{ color: 'var(--main-color)', fontSize: '1.1rem' }}>
            Vous vous attendiez à une valeur inférieure ?
          </span>
          <p className='mt-sm text-justify'>
            Personne ne connaît mieux l'affaire que l'exploitant lui-même. Bien que nous ayons tenu compte d'un grand
            nombre de facteurs pour réaliser cette évaluation, il est probable que des facteurs additionnels que nous ne
            connaissons pas viennent affecter la valeur de l'affaire à la baisse.
          </p>
          <span className='mt-sm text-justify' style={{ color: 'var(--main-color)', fontSize: '1.1rem' }}>
            Vous vous attendiez à une valeur supérieure ?
          </span>
          <p className='mt-sm text-justify'>
            En tant qu'exploitant, maître à bord de l'affaire, vous y avez consacré temps et énergie considérables. De
            ce fait, il est normal d’y affecter une valeur difficile à calculer, que l'on pourrait nommer valeur du
            travail ou valeur sentimentale. Cette valeur ne peut cependant pas être prise en compte dans l'évaluation.
          </p>
          <p className='mt-sm text-justify'>
            Si d'autres affaires ont pu être cédées à un prix « hors marché » relevant de ratios significativement
            supérieurs, rappelez-vous que nous avons pour objectif de livrer une évaluation réaliste, permettant, dans
            un projet de cession de l'affaire, d'obtenir les meilleures probabilités de trouver un repreneur dans des
            conditions de faisabilité convergentes pour les deux parties.
          </p>
          <p className='mt-sm text-justify'>
            Cependant, il est également possible que des facteurs additionnels intrinsèques à l'affaire viennent
            affecter sa valeur à la hausse.
          </p>
          <div page-break='true' />
        </div>
      </div>

      {isPdfGenerated && (
        <div className='pdf-download-container'>
          <FilledButton
            bgColor={isButtonDisabled ? 'grey' : 'var(--secondary-color)'}
            hoverColor={isButtonDisabled ? 'grey' : 'var(--secondary-color)'}
            onClick={generatePdf}
            isLoading={isPdfNotificationLoading}
            disabled={isButtonDisabled}>
            {isButtonDisabled ? (
              <>
                <Download width='30px' />
                En cours de préparation...
              </>
            ) : (
              <>
                <Download width='30px' />
                Télécharger le PDF
              </>
            )}
          </FilledButton>
        </div>
      )}

      {/* Final PDF */}
      <div className='final-pdf-container'>
        <div className='final-pdf pdf-container' ref={finalPdfContainerRef}>
          {finalPdfContent.map((page, index) => (
            <Fragment key={index}>{page}</Fragment>
          ))}
        </div>

        <div className='top-scroller-container'>
          <div className='top-scroller' onClick={scrollToTop} />
        </div>
      </div>
    </section>
  );
}

export default Pdf;
