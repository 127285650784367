import './CirclePercentagePdf.css';
import React from 'react';

function CirclePercentagePdf({ percentage, euroAmount, percentageText, euroAmountText }) {
  function formatEuroAmount(amount) {
    return String(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  function getFontSizeEuroAmount(content) {
    if (content.length < 6) return '2.3rem';
    if (content.length < 9) return '2rem';
    return '1.7rem';
  }

  return (
    <div className='circle-growth-container'>
      <div className='circle-growth-item'>
        <div className='circle-growth-percentage-amount' style={{ '--percentage': `${percentage}%` }}>
          {percentage}%
        </div>
        <p className='circle-growth-text'>{percentageText}</p>
      </div>
      <div className='circle-growth-item'>
        <div
          className='circle-growth-percentage-amount'
          style={{ fontSize: getFontSizeEuroAmount(formatEuroAmount(euroAmount)) }}>
          {formatEuroAmount(euroAmount)} €
        </div>
        <p className='circle-growth-text'>{euroAmountText}</p>
      </div>
    </div>
  );
}

export default CirclePercentagePdf;
