import React from 'react';
import './NotificationDownloadPdf.css';

function NotificationDownloadPdf({ isLoading }) {
  return (
    <>
      {isLoading && (
        <div className='download-pdf-notification-group-container'>
          <div className='download-pdf-notification-container'>
            <div className='loading-text'>Génération du PDF... Patientez un instant.</div>
            <div className='infinite-loader' />
          </div>
        </div>
      )}
    </>
  );
}

export default NotificationDownloadPdf;
