import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ParcoursContext } from '../../../../../context/ParcoursContext.js';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput.js';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import { formatObjectForPosting, getTotalFromValues } from '../../../../../utils/Utils.js';
import Tuto from '../../../../atoms/icons/general/tuto/Tuto.js';

function BilanActif({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const Form = { watch, register, setValue };
  const {
    moveToNextStep,
    getStepToGo,
    showTooltip,
    setGoToStep,
    step,
    setStep,
    submitFormsFdc,
    postValues,
    fillInputsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'bilan_actif',
      [
        'capital_souscrit',
        'immobilisations_incorporelles',
        'immobilisations_corporelles',
        'immobilisations_financieres',
        'total_immobilisations',
        'stocks_matiere_premiere',
        'stocks_marchandise',
        'creances_clients',
        'autres_creances',
        'valeurs_mobilieres_placement',
        'disponibilites',
        'charges_constatees_avance',
        'total_actif_circulant',
        'comptes_regularisation',
        'total_actif',
        'note_bilan_actif',
      ],
      setValue,
      estimationValues,
    );
  }, [estimationValues]);

  async function formSubmit(values) {
    const note = values.note_bilan_actif;
    delete values.note_bilan_actif;

    const tmp = { bilan_actif: formatObjectForPosting(values) };
    if (!secondaryParcours) tmp.bilan_actif.note_bilan_actif = note;

    postValues(tmp);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  //calcul auto des inputs
  function calcTotalImmobilisations() {
    const tmp = getValues([
      'capital_souscrit',
      'immobilisations_incorporelles',
      'immobilisations_corporelles',
      'immobilisations_financieres',
    ]);
    setValue('total_immobilisations', getTotalFromValues(tmp));

    calcTotalActif();
  }

  function calcTotalActifCirculant() {
    const tmp = getValues([
      'stocks_matiere_premiere',
      'stocks_marchandise',
      'creances_clients',
      'autres_creances',
      'valeurs_mobilieres_placement',
      'disponibilites',
      'charges_constatees_avance',
    ]);
    setValue('total_actif_circulant', getTotalFromValues(tmp));

    calcTotalActif();
  }

  function calcTotalActif() {
    const tmp = getValues(['total_immobilisations', 'total_actif_circulant', 'comptes_regularisation']);
    setValue('total_actif', getTotalFromValues(tmp));
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <p className='text-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>Bilan actif</h2>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            onChange={calcTotalImmobilisations}
            label={
              <>
                Capital souscrit non appelé <Tuto onClick={() => showTooltip('step_4_tuto_1.webp')} />
              </>
            }
            name='capital_souscrit'
            error={errors?.capital_souscrit && errors.capital_souscrit.message}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            onChange={calcTotalImmobilisations}
            label={
              <>
                Immobilisations incorporelles <Tuto onClick={() => showTooltip('step_4_tuto_2.webp')} />
              </>
            }
            name='immobilisations_incorporelles'
            error={errors?.immobilisations_incorporelles && errors.immobilisations_incorporelles.message}
            icon='euro'
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            onChange={calcTotalImmobilisations}
            label={
              <>
                Immobilisations corporelles <Tuto onClick={() => showTooltip('step_4_tuto_3.webp')} />
              </>
            }
            name='immobilisations_corporelles'
            error={errors?.immobilisations_corporelles && errors.immobilisations_corporelles.message}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            onChange={calcTotalImmobilisations}
            label={
              <>
                immobilisations financières <Tuto onClick={() => showTooltip('step_4_tuto_4.webp')} />
              </>
            }
            name='immobilisations_financieres'
            error={errors?.immobilisations_financieres && errors.immobilisations_financieres.message}
            icon='euro'
          />
        </div>
        <NumberInput
          bgColor='var(--main-color)'
          disabled
          useForm={Form}
          label='total immobilisations'
          name='total_immobilisations'
          error={errors?.total_immobilisations && errors.total_immobilisations.message}
          validation={{}}
          icon='euro'
        />
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            onChange={calcTotalActifCirculant}
            label={
              <>
                Stocks mat. 1ères & cons. <Tuto onClick={() => showTooltip('step_4_tuto_5.webp')} />
              </>
            }
            name='stocks_matiere_premiere'
            error={errors?.stocks_matiere_premiere && errors.stocks_matiere_premiere.message}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            onChange={calcTotalActifCirculant}
            label={
              <>
                Stocks marchandises <Tuto onClick={() => showTooltip('step_4_tuto_6.webp')} />
              </>
            }
            name='stocks_marchandise'
            error={errors?.stocks_marchandise && errors.stocks_marchandise.message}
            icon='euro'
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            onChange={calcTotalActifCirculant}
            label={
              <>
                Créances clients <Tuto onClick={() => showTooltip('step_4_tuto_7.webp')} />
              </>
            }
            name='creances_clients'
            error={errors?.creances_clients && errors.creances_clients.message}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            onChange={calcTotalActifCirculant}
            label={
              <>
                Autres créances <Tuto onClick={() => showTooltip('step_4_tuto_8.webp')} />
              </>
            }
            name='autres_creances'
            error={errors?.autres_creances && errors.autres_creances.message}
            icon='euro'
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            onChange={calcTotalActifCirculant}
            label={
              <>
                Valeurs mobilières de placement <Tuto onClick={() => showTooltip('step_4_tuto_9.webp')} />
              </>
            }
            name='valeurs_mobilieres_placement'
            error={errors?.valeurs_mobilieres_placement && errors.valeurs_mobilieres_placement.message}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            onChange={calcTotalActifCirculant}
            label={
              <>
                Disponibilités <Tuto onClick={() => showTooltip('step_4_tuto_10.webp')} />
              </>
            }
            name='disponibilites'
            error={errors?.disponibilites && errors.disponibilites.message}
            icon='euro'
          />
        </div>
        <NumberInput
          useForm={Form}
          onChange={calcTotalActifCirculant}
          label={
            <>
              Charges constatées d'avance <Tuto onClick={() => showTooltip('step_4_tuto_11.webp')} />
            </>
          }
          name='charges_constatees_avance'
          error={errors?.charges_constatees_avance && errors.charges_constatees_avance.message}
          icon='euro'
        />
        <NumberInput
          bgColor='var(--main-color)'
          disabled
          useForm={Form}
          label='total actif circulant'
          name='total_actif_circulant'
          error={errors?.total_actif_circulant && errors.total_actif_circulant.message}
          validation={{}}
          icon='euro'
        />
        <NumberInput
          useForm={Form}
          onChange={calcTotalActif}
          label={
            <>
              Comptes de régularisation <Tuto onClick={() => showTooltip('step_4_tuto_12.webp')} />
            </>
          }
          name='comptes_regularisation'
          error={errors?.comptes_regularisation && errors.comptes_regularisation.message}
          icon='euro'
        />
        <NumberInput
          bgColor='var(--main-color)'
          disabled
          useForm={Form}
          label='total actif'
          name='total_actif'
          error={errors?.total_actif && errors.total_actif.message}
          validation={{}}
          icon='euro'
        />
        {!secondaryParcours && (
          <Textarea
            register={register}
            error={errors?.note_bilan_actif && errors.note_bilan_actif.message}
            name='note_bilan_actif'
            label='notes et remarques'
            className='mt-md'
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        )}
      </fieldset>
    </form>
  );
}

export default BilanActif;
