import './H1Pdf.css';
import React from 'react';

function H1Pdf({ title, className = '', fontSize = '24px' }) {
  return (
    <div h2-title={title} className={`text-h1-container ${className}`}>
      <h2 style={{ fontSize: fontSize }}>{title}</h2>
    </div>
  );
}

export default H1Pdf;
