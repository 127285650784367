import React from 'react';
import FullLogo from '../../../../assets/logos/logo-cap-cession-eme-bleu-sans-fond.png';
import FullLogoDark from '../../../../assets/logos/logo-cap-cession-eme-blanc-orange-sans-fond.png';
import IconLogoDark from '../../../../assets/logos/logo-cap-cession-eme-mobile-noir-sans-fond.png';
import IconLogoLight from '../../../../assets/logos/logo-cap-cession-eme-mobile-blanc-sans-fond.png';

//typeLogo = 1 : full / 2 : icon

function EmeLogo({ dark, className, typeLogo = 1, height = '85px' }) {
  return typeLogo === 1 ? (
    <img src={dark ? FullLogoDark : FullLogo} className={className} style={{ height: height }} alt='logo' />
  ) : (
    <img src={dark ? IconLogoDark : IconLogoLight} className={className} style={{ height: height }} alt='logo' />
  );
}

export default EmeLogo;
