import React from 'react';
import LogoEntreprisePdf from '../logoEntreprisePdf/LogoEntreprisePdf';
import H1Pdf from '../h1Pdf/H1Pdf';
import InfosBlockPdf from '../infosBlockPdf/InfosBlockPdf';
import './CoverPagePdf.css';

function CoverPagePdf({ logoUrl, reportTitle, businessInfo }) {
  return (
    <div id='cover-page' className='cover-page-container'>
      <LogoEntreprisePdf width='550px' height='450px' src={logoUrl} alt="Logo de l'entreprise" />
      <H1Pdf fontSize='40px' title={reportTitle} />
      <InfosBlockPdf infos={businessInfo} />
    </div>
  );
}

export default CoverPagePdf;
