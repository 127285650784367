import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import CookieConsentModal from '../../forms/cookies/cookiesModal/CookieConsentModal';
import ScrollToTop from '../../../utils/ScrollToTop';

function Layout({ children }) {
  const location = useLocation();
  const isAccessPage = location.pathname === '/access';

  return (
    <>
      {!isAccessPage && <CookieConsentModal />}
      {!isAccessPage && <Header />}
      {!isAccessPage && <ScrollToTop />}
      {children}
      {!isAccessPage && <Footer />}
    </>
  );
}

export default Layout;
